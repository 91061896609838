import { useFetchingData, useUpdatingData } from "../../../../hooks";

export class Api {
  static read = () => {
    return useFetchingData({
      endpoint: `antrian`,
    });
  };

  static readGroup = (props) => {
    const { search } = props;
    return useFetchingData({
      endpoint: `group/antrian`,
      params: { params: { search } },
      enabled: search.length > 2 ? true : false,
      gcTime: 0,
    });
  };

  static detailAntrian = (props) => {
    const { modal } = props;
    return useFetchingData({
      endpoint: `antrian/${modal.id ? modal.id : ""}`,
      enabled: modal.open,
      gcTime: 0,
    });
  };

  static detailProgress = (props) => {
    const { modal } = props;
    return useFetchingData({
      endpoint: `antrian/progress/${modal.id ? modal.id : ""}`,
      enabled: modal.open,
      gcTime: 0,
    });
  };

  static detailAntrianGambar = (props) => {
    const { modal } = props;
    return useFetchingData({
      endpoint: `antrian/gambar/${modal.id ? modal.id : ""}`,
      enabled: modal.open,
      gcTime: 0,
    });
  };

  static printAntrian = (props) => {
    const { modal } = props;
    return useFetchingData({
      endpoint: `antrian/print/${modal.id ? modal.id : ""}`,
      enabled: modal.open,
      gcTime: 0,
    });
  };

  static pindahAntrianGroup = ({ handleClose }) => {
    return useUpdatingData({
      endpoint: `group/pindah`,
      invalidated: [`group/antrian`, `antrian`],
      onSuccess: () => {
        handleClose();
      },
      onError: () => {
        return;
      },
    });
  };

  static pindahAntrianMassGroup = ({ onSuccess }) => {
    return useUpdatingData({
      endpoint: `group/pindah/mass`,
      invalidated: [`group/antrian`, `antrian`],
      onSuccess,
      onError: () => {
        return;
      },
    });
  };
}
