import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { useMediaQuery, CssBaseline, Box } from "@mui/material";
import { CustomAppBar } from "../components/Layout/Appbar";
import { SideBar } from "../components/Layout/Drawer";
import { Footer } from "../components/Layout/Footer";
import { useDispatch, useSelector } from "react-redux";
import { updateDrawer } from "../features/themeSlice";

const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "drawerWidth",
})(({ theme, open, drawerWidth }) => ({
  ...theme.typography.mainContent,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  transition: theme.transitions.create(
    "margin",
    open
      ? {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }
      : {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }
  ),
  [theme.breakpoints.up("md")]: {
    marginLeft: open ? undefined : -drawerWidth,
    width: open ? `calc(100% - ${drawerWidth}px)` : "100%",
    padding: 30,
  },
  [theme.breakpoints.down("md")]: {
    marginLeft: open ? undefined : -drawerWidth,
    width: open ? `calc(100% - ${drawerWidth}px)` : "100%",
    padding: 20,
  },
  [theme.breakpoints.down("sm")]: {
    marginLeft: "10px",
    width: `100%`,
    padding: 20,
    marginRight: "10px",
  },
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const Layout = (props) => {
  const { children } = props;
  const { drawerOpen, drawerWidth } = useSelector((state) => state.theme);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();

  const setOpen = (props) => {
    dispatch(updateDrawer(props));
  };

  React.useEffect(() => {
    // dispatch(updateLoading(true));
    if (isSmall) {
      dispatch(updateDrawer(false));
    } else {
      dispatch(updateDrawer(true));
    }
  }, [isSmall, dispatch]);
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      {/* AppBar */}
      <CustomAppBar {...{ setOpen }} />

      {/* SideBar Drawer */}
      <SideBar {...{ setOpen }} />

      {/* Main Page */}
      <Main
        drawerWidth={drawerWidth}
        open={drawerOpen}
        theme={theme}
        style={{ paddingTop: 15 }}
      >
        {/* Top Main Content for Breadcumbs etc */}
        <DrawerHeader />

        {/* Main Content */}
        {children}
        {/* Footer */}
        <Footer />
      </Main>
    </Box>
  );
};
export default Layout;
