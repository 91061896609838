import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import { Divider, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { BlankDialog } from "../../../../components/Layout/Content";
import {
  CheckboxInput,
  CustomButton,
  Input,
} from "../../../../components/controls";
import { ActionGrid } from "../../../../components/common";
import { Api, EventHandlers } from "../repository";
import { SET_ROW_SELECTION } from "../domain/DikirimSlice";
import { SelectionMassPengiriman } from "../../../../components/Layout/Content/Checkout";

const ModalMassPengiriman = ({ antrianList }) => {
  const { control, reset, watch, handleSubmit } = useForm();
  const { socket } = useSelector((state) => state.data);

  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    reset({
      keterangan: "",
      confirmation: false,
    });
    EventHandlers.handleMassPengirimanModal({
      dispatch,
      modal: false,
    });
  }, [dispatch, reset]);

  const onSuccess = () => {
    handleClose();
    dispatch(SET_ROW_SELECTION({ rowSelection: [] }));
  };

  const { mutate: mutateMassPengiriman, isPending } = Api.massPengiriman({
    onSuccess,
    dispatch,
    socket,
  });

  const onSubmit = (values) => {
    const antrianID = antrianList.map((e) => e.id);
    mutateMassPengiriman({
      antrian_id: antrianID,
      keterangan: values.keterangan,
    });
  };

  const { massPengirimanModal } = useSelector((state) => state.checkoutDikirim);

  return (
    <BlankDialog
      maxWidth={"sm"}
      open={massPengirimanModal}
      handleClose={handleClose}
      title={"Status Keterangan Pengiriman"}
    >
      <SelectionMassPengiriman data={antrianList} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container alignItems={"center"} marginTop={3}>
          <Grid item xs={12}>
            <Input
              name="keterangan"
              control={control}
              label="Keterangan"
              rules={{ required: "Keterangan wajib di isi!" }}
              defaultValue=""
              rows={5}
            />
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent={"end"}
          alignItems={"center"}
          marginBottom={"-1rem"}
        >
          <CheckboxInput
            name={"confirmation"}
            control={control}
            label={"Pastikan data sudah benar!"}
            rules={{ required: false }}
            defaultValue={false}
          />
        </Grid>
        <Divider sx={{ marginY: 2, marginX: "-1rem" }} />
        <ActionGrid
          isLoading={isPending}
          actionStart={
            <CustomButton onClick={handleClose} warna={"clear"} fullWidth>
              Batal
            </CustomButton>
          }
          actionEnd={
            <CustomButton
              loading={isPending}
              warna={"next"}
              fullWidth
              disabled={watch("confirmation") ? false : true}
              type="submit"
            >
              Simpan
            </CustomButton>
          }
        />
      </form>
    </BlankDialog>
  );
};

export default ModalMassPengiriman;
