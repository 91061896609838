import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import {
  Payments as PaymentsIcon,
  Assignment as AntrianIcon,
} from "@mui/icons-material";
import { NumericText, FooterCard } from "../../../../../components/common";
import { useSelector } from "react-redux";

const FooterCardRekapReject = ({ data, isLoading }) => {
  const { role } = useSelector((state) => state.auth);
  const total_antrian = data ? data.length : 0;
  const total_nominal = data
    ? data.length
      ? data.map((item) => item.nominal_reject).reduce((a, b) => a + b, 0)
      : 0
    : 0;

  return (
    <Grid container direction="row" spacing={2} sx={{ marginTop: "0.1rem" }}>
      <Grid item md={role === "root" || role === "owner" ? 6 : 12}>
        <FooterCard
          title={"Total Nota"}
          value={<NumericText value={total_antrian} suffix={" Nota"} />}
          isLoading={isLoading}
          iconCard={<AntrianIcon />}
        />
      </Grid>
      {role === "root" || role === "owner" ? (
        <Grid item md={6}>
          <FooterCard
            title={"Total Nominal"}
            value={<NumericText value={total_nominal} prefix={true} />}
            isLoading={isLoading}
            iconCard={<PaymentsIcon />}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

FooterCardRekapReject.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
};
export default FooterCardRekapReject;
