import React from "react";
import PropTypes from "prop-types";
import { Box, Button } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { CustomDatagrid } from "../../../components/Layout/Content";
import {
  SET_COLUMN,
  SET_FILTER,
  SET_SORT,
  SET_PAGE,
  SET_PAGE_SIZE,
  SET_ROW_GROUP,
  SET_AGGREGATION,
  SET_PINNED_COLUMN,
} from "../domain/returSlice";
import { Config, EventHandlers } from "../repository";
import FooterCardRetur from "./TabelRetur/FooterCardRetur";
import ActionButton from "./TabelRetur/ActionButton";
import { LocalShipping as KirimIcon } from "@mui/icons-material";
import { CustomDateRange } from "../../../components/controls";

const TabelRetur = ({ data, isLoading }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const {
    tanggalReturFilter,
    page,
    pageSize,
    filterModel,
    sortModel,
    columnModel,
    rowGroup,
    aggregationModel,
    pinnedModel,
  } = useSelector((state) => state.retur);

  const { role } = useSelector((state) => state.auth);

  const { setting } = useSelector((state) => state.data);

  const statusOptions = Array.from(new Set(data.map((item) => item.status)));

  const columns = Config.column.map((e) => {
    if (e.field === "status") {
      return {
        ...e,
        valueOptions: statusOptions,
      };
    }
    if (e.field === "actions") {
      return {
        ...e,
        getActions: (params) => {
          if (typeof params.id === "number") {
            const statusDeleteAntrian = setting.find(
              (item) => item.rule === "DELETE_ANTRIAN"
            );
            return ActionButton.getActionOpsi({
              role,
              params,
              dispatch,
              statusDeleteAntrian: statusDeleteAntrian.value === "false",
            });
          }
          return [];
        },
      };
    }
    return e;
  });

  if (role === "customer_service") {
    const indexToInsertAfter = columns.findIndex(
      (e) => e.field === "metode_pembayaran"
    );

    if (indexToInsertAfter !== -1) {
      columns.splice(indexToInsertAfter + 1, 0, {
        field: "aksi_proses",
        minWidth: 150,
        headerName: "Aksi Proses",
        hideable: false,
        filterable: false,
        sortable: false,
        align: "center",
        headerAlign: "center",
        renderHeader: () => <KirimIcon sx={{ color: "#797979" }} />,
        renderCell: (params) => {
          if (typeof params.id === "number") {
            return (
              <Button
                size="small"
                startIcon={<KirimIcon sx={{ color: "rgba(229, 0, 0, 0.8)" }} />}
                variant="outlined"
                color="primary"
                sx={{
                  borderWidth: "2px",
                  borderRadius: "15px",
                  padding: "2px 15px",
                  fontSize: "10px",
                  "&:hover": {
                    borderWidth: "2px",
                  },
                }}
                onClick={() => {
                  EventHandlers.handleChangeKirimModal({
                    dispatch,
                    modal: {
                      id: params.id,
                      open: true,
                      title: "Kirim Ulang Antrian !",
                    },
                  });
                }}
              >
                Kirim Ulang
              </Button>
            );
          }
          return null;
        },
      });
    }
  }

  if (role === "root" || role === "customer_service") {
    const indexToInsertAfter = columns.findIndex((e) => e.field === "actions");
    const statusEditAntrian = setting.find(
      (item) => item.rule === "EDIT_ANTRIAN"
    );

    const statusEditSize = setting.find((item) => item.rule === "EDIT_SIZE");
    columns.splice(indexToInsertAfter, 0, {
      headerName: "Edit",
      disableClickEventBubbling: true,
      field: "edit",
      type: "actions",
      sortable: false,
      filterable: false,
      groupable: false,
      aggregable: false,
      pinnable: false,
      disableColumnMenu: true,
      width: 60,
      getActions: (params) => {
        if (typeof params.id === "number") {
          return ActionButton.getActionEdit({
            role,
            params,
            dispatch,
            statusEditAntrian:
              role !== "root" ? statusEditAntrian.value === "false" : false,
            statusEditSize:
              role !== "root" ? statusEditSize.value === "false" : false,
          });
        }
        return [];
      },
    });
  }

  return (
    <CustomDatagrid
      {...{
        rows: data,
        columns,
        isLoading: isLoading,
        columnModel,
        page,
        pageSize,
        filterModel,
        sortModel,
        rowGroup,
        aggregationModel,
        pinnedModel,
        SET_COLUMN: SET_COLUMN,
        SET_PAGE: SET_PAGE,
        SET_PAGE_SIZE: SET_PAGE_SIZE,
        SET_FILTER: SET_FILTER,
        SET_SORT: SET_SORT,
        SET_ROW_GROUP,
        SET_AGGREGATION,
        SET_PINNED_COLUMN,
      }}
      withNumberColumn={true}
      footerComponent={({ finalData }) => {
        return <FooterCardRetur data={finalData} isLoading={isLoading} />;
      }}
      customToolbar={
        <Box sx={{ p: 1 }}>
          <CustomDateRange
            title={"Filter Tanggal Retur"}
            defaultValue={tanggalReturFilter}
            onChange={(values) =>
              EventHandlers.handleFilterTanggal({
                dispatch,
                queryClient,
                tanggal: values,
              })
            }
          />
        </Box>
      }
    />
  );
};

TabelRetur.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default TabelRetur;
