import React from "react";
import PropTypes from "prop-types";
import { Box, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  FolderCopy as ManageGroupIcon,
  CreateNewFolder as AddGroup,
} from "@mui/icons-material";
import { CustomDatagrid } from "../../../../components/Layout/Content";
import {
  SET_COLUMN,
  SET_FILTER,
  SET_SORT,
  SET_PAGE,
  SET_PAGE_SIZE,
  SET_ROW_GROUP,
  SET_AGGREGATION,
  SET_PINNED_COLUMN,
  SET_ROW_SELECTION,
} from "../domain/manageGroupNotaSlice";
import { Config, EventHandlers } from "../repository";
import FooterCardManageGroup from "./TabelManageGroup/FooterCardManageGroup";
import ActionButton from "./TabelManageGroup/ActionButton";

const TabelManageGroup = ({ data, isLoading, jenisOrder, sourceOrder }) => {
  const dispatch = useDispatch();

  const {
    page,
    pageSize,
    filterModel,
    sortModel,
    columnModel,
    rowGroup,
    aggregationModel,
    pinnedModel,
    rowSelectionModel,
  } = useSelector((state) => state.manageGroupNota);

  const { role } = useSelector((state) => state.auth);

  const statusOptions = Array.from(new Set(data.map((item) => item.status)));

  const jenisOrderOptions = Array.from(
    new Set(jenisOrder.map((item) => item.name))
  );

  const sourceOrderOptions = Array.from(
    new Set(sourceOrder.map((item) => item.name))
  );

  const columns = Config.column.map((e) => {
    if (e.field === "status") {
      return {
        ...e,
        valueOptions: statusOptions,
      };
    }
    if (e.field === "jenis_order") {
      return {
        ...e,
        valueOptions: jenisOrderOptions,
      };
    }
    if (e.field === "source_order") {
      return {
        ...e,
        valueOptions: sourceOrderOptions,
      };
    }
    if (e.field === "preview") {
      return {
        ...e,
        getActions: (params) =>
          ActionButton.getActionPreview({ role, params, dispatch }),
      };
    }
    if (e.field === "actions") {
      return {
        ...e,
        getActions: (params) =>
          ActionButton.getActionOpsi({ params, dispatch }),
      };
    }
    return e;
  });

  if (role === "penata") {
    const indexToInsertAfter = columns.findIndex(
      (e) => e.field === "status_pembayaran"
    );

    if (indexToInsertAfter !== -1) {
      columns.splice(indexToInsertAfter + 1, 0, {
        field: "group",
        headerName: "Group",
        type: "boolean",
        minWidth: 150,
        hideable: false,
        sortable: false,
        groupable: false,
        align: "center",
        headerAlign: "center",
        renderHeader: () => <ManageGroupIcon sx={{ color: "797979" }} />,
        renderCell: (params) => {
          return (
            <Button
              size="small"
              startIcon={
                params.value ? (
                  <ManageGroupIcon sx={{ color: "#fff" }} />
                ) : (
                  <AddGroup sx={{ color: "#2FA9EE" }} />
                )
              }
              variant={params.value ? "contained" : "outlined"}
              sx={{
                borderRadius: 15,
                textTransform: "capitalize",
                padding: "4px 15px",
                backgroundColor: params.value ? "#7397AB" : "",
                color: params.value ? "#fff" : "#2FA9EE",
                fontSize: "10px",
                "&:hover": {
                  backgroundColor: params.value ? "#5c88a1" : "",
                  borderColor: params.value ? "" : "#2FA9EE",
                },
              }}
              onClick={() => {
                EventHandlers.handleManageGroupModal({
                  dispatch,
                  modal: {
                    id: params.id,
                    open: true,
                    title: "Manage Group !",
                  },
                });
              }}
            >
              {params.value ? params.value : "Group"}
            </Button>
          );
        },
      });
    }
  }

  return (
    <CustomDatagrid
      {...{
        rows: data,
        columns,
        isLoading: isLoading,
        columnModel,
        page,
        pageSize,
        filterModel,
        sortModel,
        rowGroup,
        aggregationModel,
        pinnedModel,
        SET_COLUMN: SET_COLUMN,
        SET_PAGE: SET_PAGE,
        SET_PAGE_SIZE: SET_PAGE_SIZE,
        SET_FILTER: SET_FILTER,
        SET_SORT: SET_SORT,
        SET_ROW_GROUP,
        SET_AGGREGATION,
        SET_PINNED_COLUMN,
      }}
      getRowClassName={(e) =>
        (e.row.prioritas && "datagrid-prioritas") ||
        (e.row.groupped && "datagrid-groupped")
      }
      footerComponent={({ finalData }) => {
        return <FooterCardManageGroup data={finalData} isLoading={isLoading} />;
      }}
      withNumberColumn={true}
      rowSelectionModel={rowSelectionModel}
      checkboxSelection={true}
      SET_ROW_SELECTION={SET_ROW_SELECTION}
      customToolbar={
        <Box sx={{ p: 1 }}>
          <Button
            size="small"
            startIcon={<ManageGroupIcon sx={{ color: "#2FA9EE" }} />}
            variant="outlined"
            sx={{
              borderRadius: 15,
              textTransform: "capitalize",
              padding: "2px 15px",
              color: "#2FA9EE",
              fontSize: "10px",
              "&:hover": {
                borderColor: "#2FA9EE",
              },
            }}
            disabled={rowSelectionModel.length < 1}
            onClick={() => {
              EventHandlers.handleGroupSelectionGroupModal({
                dispatch,
                modal: true,
              });
            }}
          >
            Group Selected ({rowSelectionModel.length})
          </Button>
        </Box>
      }
    />
  );
};

TabelManageGroup.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  jenisOrder: PropTypes.array,
  sourceOrder: PropTypes.array,
  statusList: PropTypes.array,
};

export default TabelManageGroup;
