import { createSlice } from "@reduxjs/toolkit";

const dataSlice = createSlice({
  name: "data",
  initialState: {
    uuid: null,
    setting: [],
    socket: null,
    serial_number: null,
    app_version: "",
    description: "",
    default_bulan: [],
    default_tahun: [],
    pembayaran_notification: {
      title : null,
      deskripsi: null,
      tgl_jatuhtempo: null
    },
  },

  reducers: {
    updateUUID: (state, action) => {
      state.uuid = action.payload;
    },
    UPDATE_SETTING: (state, action) => {
      state.setting = action.payload;
    },
    UPDATE_SOCKET: (state, action) => {
      state.socket = action.payload;
    },
    UPDATE_SERIAL_NUMBER: (state, action) => {
      state.serial_number = action.payload;
    },
    UPDATE_APP_VERSION: (state, action) => {
      state.app_version = action.payload;
    },
    UPDATE_DESCRIPTION: (state, action) => {
      state.description = action.payload;
    },
    SET_DEFAULT_BULAN: (state, action) => {
      state.default_bulan = action.payload;
    },
    SET_DEFAULT_TAHUN: (state, action) => {
      state.default_tahun = action.payload;
    },
    SET_PEMBAYARAN_NOTIFICATION: (state, action) => {
      state.pembayaran_notification = action.payload;
    },
  },
});

export const {
  updateUUID,
  UPDATE_SETTING,
  UPDATE_SOCKET,
  UPDATE_SERIAL_NUMBER,
  UPDATE_APP_VERSION,
  UPDATE_DESCRIPTION,
  SET_DEFAULT_BULAN,
  SET_DEFAULT_TAHUN,
  SET_PEMBAYARAN_NOTIFICATION,
} = dataSlice.actions;
export default dataSlice.reducer;
