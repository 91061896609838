import { Avatar, Tooltip } from "@mui/material";
import { checkColor, dateFormat } from "../../../../components/controls";
import { Payments as PaymentsIcon } from "@mui/icons-material";
import { ChipItems } from "../../../../components/common";
import config from "../../../../config";

export class Config {
  static column = [
    {
      field: "nota",
      flex: 1,
      minWidth: 70,
      hideable: false,
      headerName: "No. Nota",
    },
    {
      field: "nama",
      flex: 1,
      minWidth: 150,
      headerName: "Leads / Nama",
    },
    {
      field: "no_invoice",
      headerName: "No. Invoice",
      width: 150,
      renderCell: (params) => params.row.nota + " " + params.row.nama,
    },
    {
      field: "check_out",
      type: "date",
      flex: 1,
      minWidth: 150,
      headerName: "Check-Out",
      valueGetter: (params) => (params ? new Date(params.tanggal) : null),
      renderCell: (params) => {
        if (params.value instanceof Date && !isNaN(params.value)) {
          return (
            <span
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {dateFormat(params.value, "", "-")}
            </span>
          );
        }
        return "";
      },
      groupingValueGetter: (params) => {
        var date = new Date(params.tanggal);
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        return new Date(`${year}-${month}-${day}`);
      },
    },
    {
      field: "status",
      type: "singleSelect",
      flex: 1,
      minWidth: 150,
      headerName: "Progress",
      renderCell: (params) => {
        if (params.rowNode.type === "group") {
          if (params.value) {
            return (
              <ChipItems
                label={params.value.status}
                backgroundcolor={params.value.warna}
                padding={"0 0.5rem"}
              />
            );
          }
        }

        if (params.row.status) {
          if (params.row.status.status && params.row.status.warna) {
            return (
              <>
                <ChipItems
                  label={params.row.status.status}
                  backgroundcolor={params.row.status.warna}
                  padding={"0 0.5rem"}
                />
              </>
            );
          } else {
            return false;
          }
        }
        return "";
      },
      valueGetter: (value) => {
        return value?.status;
      },
      groupingValueGetter: (value) => {
        value.toString = () => value.status;
        return value || null;
      },
    },
    {
      field: "status_pembayaran",
      type: "singleSelect",
      flex: 1,
      minWidth: 150,
      headerName: "Pembayaran",
      valueOptions: ["Lunas", "Belum Lunas"],
      renderCell: (params) => {
        if (params.value) {
          return (
            <ChipItems
              icon={<PaymentsIcon />}
              label={params.value}
              backgroundcolor={checkColor(params.value)}
              borderradius="3px"
              iconmarginleft="8px"
            />
          );
        }
        return "";
      },
    },
    {
      field: "alamat",
      flex: 1,
      minWidth: 150,
      headerName: "Alamat",
    },
    {
      field: "source_order",
      type: "singleSelect",
      flex: 1,
      minWidth: 150,
      headerName: "Source Order",
    },
    {
      field: "jenis_order",
      type: "singleSelect",
      flex: 1,
      minWidth: 150,
      headerName: "Jenis Order",
    },
    {
      field: "desainer",
      flex: 1,
      minWidth: 150,
      headerName: "Desainer",
      align: "center",
      headerAlign: "center",
      filterable: false,
      sortable: false,
      display: "flex",
      renderCell: (desainer) => {
        if (desainer.rowNode.type !== "group" && !desainer.value) {
          return "-";
        }

        if (desainer.value === "kosong") {
          return "N/A";
        }

        if (desainer.value && desainer.value.nama) {
          return (
            <Tooltip
              title={desainer.value.nama}
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, -20],
                      },
                    },
                  ],
                },
              }}
            >
              <Avatar
                alt={desainer.value.nama}
                sx={{ bgcolor: desainer.value.color }}
                src={
                  desainer.value.photo
                    ? `${config.apiUrl}/storage/user/${desainer.value.photo}`
                    : undefined
                }
              >
                {desainer.value.nama.charAt(0)}
              </Avatar>
            </Tooltip>
          );
        }

        return "";
      },
      groupingValueGetter: (value) => {
        if (value) {
          value.toString = () => `${value.nama}`;
        } else {
          value = "kosong";
        }
        return value;
      },
    },
    {
      field: "produksi",
      flex: 1,
      minWidth: 150,
      headerName: "Produksi",
      align: "center",
      headerAlign: "center",
      filterable: false,
      sortable: false,
      display: "flex",
      renderCell: (produksi) => {
        if (produksi.rowNode.type !== "group" && !produksi.value) {
          return "-";
        }

        if (produksi.value === "kosong") {
          return "N/A";
        }

        if (produksi.value && produksi.value.nama) {
          return (
            <Tooltip
              title={produksi.value.nama}
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, -20],
                      },
                    },
                  ],
                },
              }}
            >
              <Avatar
                alt={produksi.value.nama}
                sx={{ bgcolor: produksi.value.color }}
                src={
                  produksi.value.photo
                    ? `${config.apiUrl}/storage/user/${produksi.value.photo}`
                    : undefined
                }
              >
                {produksi.value.nama.charAt(0)}
              </Avatar>
            </Tooltip>
          );
        }

        return "";
      },
      groupingValueGetter: (value) => {
        if (value) {
          value.toString = () => `${value.nama}`;
        } else {
          value = "kosong";
        }
        return value;
      },
    },
    {
      headerName: "Opsi",
      disableClickEventBubbling: true,
      field: "actions",
      type: "actions",
      sortable: false,
      filterable: false,
      groupable: false,
      aggregable: false,
      pinnable: false,
      disableColumnMenu: true,
      width: 60,
    },
  ];

  static variable = {
    nama: "Rekap",
    endpoint: "rekap",
  };
}
