import { GridActionsCellItem } from "@mui/x-data-grid";
import {
  Info as InfoIcon,
  PendingActions as ProgressIcon,
  Photo as PhotoIcon,
  FileUpload as FileUploadIcon,
  Warning as WarningIcon,
} from "@mui/icons-material";
import { EventHandlers } from "../../repository";

class ActionButton {
  static getActionOpsi = ({ params, dispatch, role }) => {
    let produksiActions = [];
    const commonActions = [
      <GridActionsCellItem
        icon={<InfoIcon sx={{ color: "#2FA9EE" }} />}
        label="Detail"
        onClick={() =>
          EventHandlers.handleDetailModal({
            dispatch,
            modal: {
              id: params.id,
              open: true,
              title: "Detail Antrian",
            },
          })
        }
        showInMenu
      />,
      <GridActionsCellItem
        icon={<ProgressIcon sx={{ color: "#24BC85" }} />}
        label="Progress"
        onClick={() => {
          EventHandlers.handleDetailProgress({
            dispatch,
            modal: {
              id: params.id,
              open: true,
              title: "Detail Progress",
            },
          });
        }}
        showInMenu
      />,
    ];

    if (role === "admin_produksi") {
      produksiActions = [
        <GridActionsCellItem
          icon={<WarningIcon sx={{ color: "#FF0000" }} />}
          label="Edit Reject"
          onClick={() =>
            EventHandlers.handleEditReject({
              dispatch,
              modal: {
                id: params.id,
                open: true,
                title: "Edit Reject !",
              },
            })
          }
          showInMenu
        />,
      ];
    }
    return [...commonActions, ...produksiActions];
  };

  static getActionPreview = ({ role, params, dispatch }) => {
    if (role === "desainer" || role === " customer_service") {
      return [
        <GridActionsCellItem
          icon={<PhotoIcon />}
          label="Preview"
          onClick={() =>
            EventHandlers.handlePreview({
              dispatch,
              file: params.row.gambar,
              title: `Detail Gambar Nota : ${params.row.nota}`,
            })
          }
          sx={{
            "& svg": {
              fontSize: "22px",
            },
            "&:hover svg": {
              color: "#2FA9EE",
            },
          }}
          disabled={params.row.gambar ? false : true}
        />,
        <GridActionsCellItem
          icon={<FileUploadIcon />}
          label="Upload"
          onClick={() =>
            EventHandlers.handleUploadPreviewModal({
              dispatch,
              modal: {
                id: params.id,
                open: true,
                title: "Upload Preview !",
              },
            })
          }
          sx={{
            "& svg": {
              fontSize: "22px",
            },
            "&:hover svg": {
              color: "#2FA9EE",
            },
          }}
        />,
      ];
    } else {
      return [
        <GridActionsCellItem
          icon={<PhotoIcon />}
          label="Preview"
          onClick={() =>
            EventHandlers.handlePreview({
              dispatch,
              file: params.row.gambar,
              title: `Detail Gambar Nota : ${params.row.nota}`,
            })
          }
          sx={{
            "& svg": {
              fontSize: "22px",
            },
            "&:hover svg": {
              color: "#2FA9EE",
            },
          }}
          disabled={params.row.gambar ? false : true}
        />,
      ];
    }
  };
}

export default ActionButton;
