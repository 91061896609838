import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import {
  Payments as PaymentsIcon,
  Assignment as AntrianIcon,
  ShoppingCart as QtyIcon,
} from "@mui/icons-material";
import { NumericText, FooterCard } from "../../../../../components/common";
import { useSelector } from "react-redux";

const FooterCardPencapaian = ({ data, isLoading }) => {
  const { role } = useSelector((state) => state.auth);
  const total_antrian = data ? data.length : 0;
  const total_nominal = data
    ? data.length
      ? data.map((item) => item.total_pembelian).reduce((a, b) => a + b, 0)
      : 0
    : 0;

  const total_qty = data
    ? data.length
      ? data.map((item) => item.total_qty).reduce((a, b) => a + b, 0)
      : 0
    : 0;

  return (
    <Grid container direction="row" spacing={2} sx={{ marginTop: "0.1rem" }}>
      <Grid item md={role === "root" || role === "owner" ? 4 : 6}>
        <FooterCard
          title={"Total Antrian"}
          value={<NumericText value={total_antrian} suffix={" Antrian"} />}
          isLoading={isLoading}
          iconCard={<AntrianIcon />}
        />
      </Grid>
      <Grid item md={role === "root" || role === "owner" ? 4 : 6}>
        <FooterCard
          title={"Total Qty"}
          value={<NumericText value={total_qty} suffix={" Pcs"} />}
          isLoading={isLoading}
          iconCard={<QtyIcon />}
        />
      </Grid>
      {role === "root" || role === "owner" ? (
        <Grid item md={4}>
          <FooterCard
            title={"Total Nominal"}
            value={<NumericText value={total_nominal} prefix={true} />}
            isLoading={isLoading}
            iconCard={<PaymentsIcon />}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

FooterCardPencapaian.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
};
export default FooterCardPencapaian;
