import config from "../../../../config";
import {
  SET_DETAIL_MODAL,
  SET_DETAIL_PROGRESS_MODAL,
  SET_PROGRESS_PRODUKSI_MODAL,
  SET_REFETCH_STATUS,
  SET_SELESAI_MODAL,
  SET_UPLOAD_PREVIEW_MODAL,
  SET_EDIT_REJECT,
} from "../domain/dikerjakanNotaSlice";
import { UPDATE_IMAGE } from "../../../imageDetailSlice";

export class EventHandlers {
  static handlePreview = ({ dispatch, file, title }) => {
    dispatch(
      UPDATE_IMAGE({
        url: `${config.apiUrl}/storage/antrian/${file}`,
        title: title,
      })
    );
  };

  static handleDetailModal = ({ dispatch, modal }) => {
    dispatch(SET_DETAIL_MODAL({ modal }));
  };

  static handleUploadPreviewModal = ({ dispatch, modal }) => {
    dispatch(SET_UPLOAD_PREVIEW_MODAL({ modal }));
  };

  static handleDetailProgress = ({ dispatch, modal }) => {
    dispatch(SET_DETAIL_PROGRESS_MODAL({ modal }));
  };

  static handleSelesaiModal = ({ dispatch, modal }) => {
    dispatch(SET_SELESAI_MODAL({ modal }));
  };

  static handleProgressProduksiModal = ({ dispatch, modal }) => {
    dispatch(SET_PROGRESS_PRODUKSI_MODAL({ modal }));
  };

  static handleRefetchStatus = ({ dispatch, refetch_status }) => {
    dispatch(SET_REFETCH_STATUS({ refetch_status }));
  };

  static handleEditReject = ({ dispatch, modal }) => {
    dispatch(SET_EDIT_REJECT({ modal }));
  };
}
