import React, { useState } from "react";
import { BlankDialog } from "../Layout/Content";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { Box, Card, Typography } from "@mui/material";

const NotifyPayment = () => {
  const [open, setOpen] = useState(false);
  const [jatuhTempo, setJatuhTempo] = useState(false);
  const [jatuhTempoFormatter, setJatuhTempoFormatter] = useState("");
  const { pembayaran_notification } = useSelector((state) => state.data);
  //   console.log(pembayaran_notification);

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    const isValid = today >= pembayaran_notification.tgl_jatuhtempo;

    setJatuhTempo(isValid);
    const date = new Date(pembayaran_notification.tgl_jatuhtempo);
    const day = date.getDate().toString().padStart(2, "0"); // Tambah 0 di depan kalau kurang dari 10
    const month = date.toLocaleDateString("id-ID", { month: "long" });
    const year = date.getFullYear();
    const dayName = date.toLocaleDateString("id-ID", { weekday: "long" }); // Ambil nama hari

    const formatTanggal = `${dayName}, ${day} ${month} ${year}`;
    setJatuhTempoFormatter(formatTanggal);
  }, [pembayaran_notification.tgl_jatuhtempo]);

  useEffect(() => {
    if (pembayaran_notification.title) {
      setOpen(true);
    }
  }, [pembayaran_notification.title]);

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach(() => {
        if (!document.getElementById("notify-payment")) {
          if (jatuhTempo && open) {
            alert("HAHAHAHAHAHAHA");
            window.location.reload();
          }
        }
      });
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, [jatuhTempo]);

  return (
    <BlankDialog
      open={open}
      handleClose={() => {
        !jatuhTempo && setOpen(false);
      }}
      maxWidth="sm"
      title={
        <Typography sx={{ fontSize: 14, fontWeight: 700 }}>
          {pembayaran_notification.title}
        </Typography>
      }
      outsideClose={true}
      isLoading={false}
      hideCloseIcon={jatuhTempo ? true : false}
    >
      <Box textAlign={"center"} id="notify-payment">
        <Card
          sx={{
            backgroundColor: "#ebebeb",
            boxShadow: "none",
            padding: 1,
            mb: 1,
          }}
        >
          <Typography variant="subtitle1">
            {pembayaran_notification.deskripsi}
          </Typography>
        </Card>
        {jatuhTempo ? (
          <Typography variant="subtitle1">
            Silahkan segera melakukan pembayaran untuk tetap bisa menikmati
            layanan aplikasi WAVE
          </Typography>
        ) : (
          <Typography variant="subtitle1">
            Silahkan segera melakukan pembayaran sebelum jatuh tempo untuk tetap
            bisa menikmati layanan update aplikasi WAVE
          </Typography>
        )}

        <Typography sx={{ fontSize: 16, fontWeight: 700, color: "red", mt: 1 }}>
          {jatuhTempoFormatter}
        </Typography>
      </Box>
    </BlankDialog>
  );
};

export default NotifyPayment;
