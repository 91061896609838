import React from "react";
import { useGetCachedData } from "../../../hooks";
import {
  DetailAntrian,
  DetailPembayaran,
  DetailProgress,
  ModalUpload,
  TabelDikirim,
  ModalSelesai,
  ModalProgress,
  PrintAntrian,
  ModalChangePerson,
  ModalHapusAntrian,
  ModalMassPengiriman,
} from "./components";
import { Api } from "./repository";
import ModalEditDetailOrder from "./components/ModalEditDetailOrder";
import ModalEditSizeQty from "./components/ModalEditSizeQty";
import { useSelector } from "react-redux";

const DikirimPage = () => {
  const getDataAntrian = Api.read();
  const { rowSelectionModel } = useSelector((state) => state.checkoutDikirim);

  const getDataSourceOrder = useGetCachedData({ queryKey: "source_order" });
  const getDataJenisOrder = useGetCachedData({ queryKey: "jenis_order" });
  const getDataEkspedisi = useGetCachedData({ queryKey: "ekspedisi" });
  const getDataSize = useGetCachedData({ queryKey: "size" });
  const getDataAdditional = useGetCachedData({ queryKey: "additional" });
  const getDataKeterangan = useGetCachedData({ queryKey: "keterangan" });

  return (
    <>
      {/* TABEL ANTRIAN */}
      <TabelDikirim
        data={getDataAntrian.data ? getDataAntrian.data.data : []}
        isLoading={getDataAntrian.isLoading}
        sourceOrder={getDataSourceOrder ? getDataSourceOrder.data : []}
        jenisOrder={getDataJenisOrder ? getDataJenisOrder.data : []}
      />

      {/* POPUPS */}
      <ModalChangePerson />

      <ModalProgress />

      <ModalHapusAntrian />

      <ModalUpload />

      <DetailAntrian />

      <DetailProgress />

      <DetailPembayaran />

      <ModalSelesai />

      <PrintAntrian />

      {/* editAntrian */}
      <ModalEditDetailOrder
        {...{
          sourceOrder: getDataSourceOrder ? getDataSourceOrder.data : [],
          jenisOrder: getDataJenisOrder ? getDataJenisOrder.data : [],
          ekspedisi: getDataEkspedisi ? getDataEkspedisi.data : [],
        }}
      />
      <ModalEditSizeQty
        {...{
          size: getDataSize ? getDataSize.data : [],
          additional: getDataAdditional ? getDataAdditional.data : [],
          keterangan: getDataKeterangan ? getDataKeterangan.data : [],
        }}
      />

      <ModalMassPengiriman
        antrianList={
          getDataAntrian.data || rowSelectionModel.length > 0
            ? getDataAntrian.data.data.filter((item) =>
                rowSelectionModel.includes(item.id)
              )
            : []
        }
      />
    </>
  );
};

export default DikirimPage;
