import React from "react";
import TabelAntrianGroup from "../DataGroup/TabelAntrianGroup";
import { checkDate, dateFormat } from "../../../controls";
import { ChipItems, NumericText } from "../../../common";

// column antrian
const totalAntrian = [
  {
    field: "no",
    headerName: "No",
    flex: 1,
    filterable: false,
    minWidth: 50,
    maxWidth: 50,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => {
      const jarak = checkDate(params.row.check_in.tanggal);
      return (
        <span
          style={{
            ...(jarak.interval > 2 && {
              color: jarak.warna,
              ...(jarak.interval > 4 && { fontWeight: "bold" }),
            }),
          }}
        >
          {params.value}
        </span>
      );
    },
  },
  {
    field: "status",
    headerName: "Progress",
    minWidth: 110,
    renderHeader: () => <strong>Progress</strong>,
    valueGetter: (value) => value.status,
    renderCell: (params) => {
      return (
        <ChipItems
          size="small"
          label={params.value}
          backgroundcolor={params.row.status.warna}
          borderradius={"3px"}
          sx={{ height: "20px" }}
        />
      );
    },
  },
  {
    field: "keterangan",
    headerName: "Keterangan",
    minWidth: 110,
    renderHeader: () => <strong>Keterangan</strong>,
    valueGetter: (_, row) => row.status.keterangan,
  },
  {
    field: "nota",
    flex: 1,
    minWidth: 100,
    hideable: false,
    headerName: "No. Nota",
    renderCell: (params) => {
      const jarak = checkDate(params.row.check_in.tanggal);
      return (
        <span
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            ...(jarak.interval > 2 && {
              color: jarak.warna,
              ...(jarak.interval > 4 && { fontWeight: "bold" }),
            }),
          }}
        >
          {params.value}
          {jarak.interval >= 2 && (
            <sup
              style={{
                color: jarak.warna,
                fontWeight: "bold",
                fontSize: "10px",
              }}
            >
              {" "}
              {jarak.interval > 0 && jarak.interval}
            </sup>
          )}
        </span>
      );
    },
  },
  {
    field: "nama",
    sortable: false,
    minWidth: 50,
    headerName: "Leads / Nama",
    renderCell: (params) => {
      const jarak = checkDate(params.row.check_in.tanggal);
      return (
        <span
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            ...(jarak.interval > 2 && {
              color: jarak.warna,
              ...(jarak.interval > 4 && { fontWeight: "bold" }),
            }),
          }}
        >
          {params.value}
        </span>
      );
    },
  },
  {
    field: "check_in",
    type: "date",
    flex: 1,
    minWidth: 100,
    headerName: "Check-In",
    valueGetter: (value) => new Date(value.tanggal),
    renderCell: (params) => {
      const jarak = checkDate(params.row.check_in.tanggal);
      if (params.value instanceof Date && !isNaN(params.value)) {
        return (
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              ...(jarak.interval > 2 && {
                color: jarak.warna,
                ...(jarak.interval > 4 && { fontWeight: "bold" }),
              }),
            }}
          >
            {dateFormat(params.value)}
          </span>
        );
      }
      return "";
    },
  },
  {
    field: "order",
    type: "number",
    sortable: false,
    minWidth: 50,
    headerName: "Order",
    renderCell: (params) => {
      const jarak = checkDate(params.row.check_in.tanggal);
      return (
        <span
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            ...(jarak.interval > 2 && {
              color: jarak.warna,
              ...(jarak.interval > 4 && { fontWeight: "bold" }),
            }),
          }}
        >
          <NumericText
            value={params.row.total_qty}
            thousandSeparator
            prefix={false}
            suffix={" Pcs"}
            displayType="text"
          />
        </span>
      );
    },
  },
];

const SelectionMassPengiriman = ({ data }) => {
  const rowsAntrianData = data.map((row, index) => ({
    ...row,
    no: index + 1,
  }));

  return (
    <TabelAntrianGroup
      rows={rowsAntrianData}
      column={totalAntrian}
      getRowClassName={(e) => e.row.prioritas && "datagrid-prioritas"}
    />
  );
};

export default SelectionMassPengiriman;
