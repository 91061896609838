import { createSlice } from "@reduxjs/toolkit";

const groupDikerjakanNota = JSON.parse(localStorage.getItem("dikerjakanNota"));

const initialState = {
  page: 0,
  pageSize: 10,
  filterModel: {
    items: [],
  },
  sortModel: [],
  columnModel: {
    jenis_order: false,
    source_order: false,
    alamat: false,
  },
  rowGroup: groupDikerjakanNota ?? [],
  aggregationModel: {},
  pinnedModel: { left: [], right: ["actions"] },
  uploadPreviewModal: { id: null, open: false, title: null },
  detailModal: { id: null, open: false, title: null },
  detailProgressModal: { id: null, open: false, title: null },
  selesaiModal: { id: null, open: false, title: null },
  progressProduksiModal: { id: null, open: false, title: null },
  refetch_status: false,
  editReject: { id: null, open: false, title: null },
};

const DikerjakanNota = createSlice({
  name: "dikerjakanNota",
  initialState,
  reducers: {
    SET_PAGE: (state, action) => {
      state.page = action.payload.page;
    },
    SET_PAGE_SIZE: (state, action) => {
      state.pageSize = action.payload.pageSize;
    },
    SET_FILTER: (state, action) => {
      state.filterModel = action.payload.filter;
    },
    SET_SORT: (state, action) => {
      state.sortModel = action.payload.sort;
    },
    SET_COLUMN: (state, action) => {
      state.columnModel = action.payload.model;
    },
    SET_ROW_GROUP: (state, action) => {
      localStorage.setItem(
        "dikerjakanNota",
        JSON.stringify(action.payload.group)
      );
      state.rowGroup = action.payload.group;
    },
    SET_AGGREGATION: (state, action) => {
      state.aggregationModel = action.payload.aggregates;
    },
    SET_PINNED_COLUMN: (state, action) => {
      state.pinnedModel = action.payload.pinned;
    },
    SET_UPLOAD_PREVIEW_MODAL: (state, action) => {
      state.uploadPreviewModal = action.payload.modal;
    },
    SET_DETAIL_MODAL: (state, action) => {
      state.detailModal = action.payload.modal;
    },
    SET_DETAIL_PROGRESS_MODAL: (state, action) => {
      state.detailProgressModal = action.payload.modal;
    },
    SET_SELESAI_MODAL: (state, action) => {
      state.selesaiModal = action.payload.modal;
    },
    SET_PROGRESS_PRODUKSI_MODAL: (state, action) => {
      state.progressProduksiModal = action.payload.modal;
    },
    SET_REFETCH_STATUS: (state, action) => {
      state.refetch_status = action.payload.refetch_status;
    },
    SET_EDIT_REJECT: (state, action) => {
      state.editReject = action.payload.modal;
    },
  },
});

export const {
  SET_PAGE,
  SET_PAGE_SIZE,
  SET_FILTER,
  SET_SORT,
  SET_COLUMN,
  SET_ROW_GROUP,
  SET_AGGREGATION,
  SET_PINNED_COLUMN,
  SET_DETAIL_MODAL,
  SET_UPLOAD_PREVIEW_MODAL,
  SET_PEMBAYARAN_MODAL,
  SET_DETAIL_PROGRESS_MODAL,
  SET_SELESAI_MODAL,
  SET_PROGRESS_PRODUKSI_MODAL,
  SET_REFETCH_STATUS,
  SET_EDIT_REJECT,
} = DikerjakanNota.actions;

export default DikerjakanNota.reducer;
