import React from "react";
import { useSelector } from "react-redux";
import { useGetCachedData } from "../../../hooks";
import {
  DetailAntrian,
  DetailProgress,
  ModalEditReject,
  ModalUploadAntrian,
  TabelDikerjakan,
} from "./components";
import { Api } from "./repository";
import { ModalSelesai, ModalProgressProduksi } from "./components";

const NotaPage = () => {
  const { detailModal, detailProgressModal, uploadPreviewModal } = useSelector(
    (state) => state.dikerjakanNota
  );

  const getDataAntrian = Api.read();
  const getAntrianDetail = Api.detailAntrian({ modal: detailModal });
  const getAntrianGambar = Api.detailAntrianGambar({
    modal: uploadPreviewModal,
  });
  const getProgressDetail = Api.detailProgress({ modal: detailProgressModal });

  const getDataSourceOrder = useGetCachedData({ queryKey: "source_order" });
  const getDataJenisOrder = useGetCachedData({ queryKey: "jenis_order" });

  return (
    <>
      {/* TABEL ANTRIAN */}
      <TabelDikerjakan
        data={getDataAntrian.data ? getDataAntrian.data.data : []}
        isLoading={getDataAntrian.isLoading}
        sourceOrder={getDataSourceOrder ? getDataSourceOrder.data : []}
        jenisOrder={getDataJenisOrder ? getDataJenisOrder.data : []}
      />

      {/* POPUPS */}
      <ModalUploadAntrian
        data={
          getAntrianGambar.data ? getAntrianGambar.data.data : { gambar: null }
        }
        isLoading={getAntrianGambar.isLoading}
      />

      <DetailAntrian
        data={getAntrianDetail.data ? getAntrianDetail.data.data : {}}
        isLoading={getAntrianDetail.isLoading}
        isError={getAntrianDetail.isError}
      />

      <DetailProgress
        data={getProgressDetail.data ? getProgressDetail.data.data : {}}
        isLoading={getProgressDetail.isLoading}
        isError={getProgressDetail.isError}
      />

      <ModalSelesai />

      <ModalProgressProduksi />

      <ModalEditReject />
    </>
  );
};

export default NotaPage;
