import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import {
  useTheme,
  Box,
  Grid,
  Paper,
  Typography,
  Button,
  Collapse,
  IconButton,
  Pagination,
  Avatar,
  AvatarGroup,
  Tooltip,
  Skeleton,
} from "@mui/material";
import TotalAntrian from "./DataGroup/TotalAntrian";
import TotalOrder from "./DataGroup/TotalOrder";
import TotalTambahan from "./DataGroup/TotalTambahan";
import CollapseButton from "../../common/CollapseButton";
import LoadingProgress from "../../common/LoadingProgress";

import {
  LocalFireDepartment as FireIcon,
  Edit as EditIcon,
  Print as PrintIcon,
  DoneOutline as DoneIcon,
  AddToPhotos as AddIcon,
  DeleteSweep as BlackIcon,
  Delete as DeleteIcon,
  Info as InfoIcon,
  Image as ImageIcon,
} from "@mui/icons-material";
import { AddButton, dateFormat, dateFormatter } from "../../controls";
import { useGetCachedData } from "../../../hooks";
import config from "../../../config";
import EmptyDataOverlay from "./CustomDatagrid/EmptyDataOverlay";
import { updateLoading } from "../../../features/themeSlice";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

// add button
const AddGroup = ({ onClickAddGroup }) => {
  return <AddButton title="Group" onClick={onClickAddGroup} />;
};

const LayoutGroup = ({
  currentPage,
  pageSize,
  search,
  filterStatus,
  filterPriority,
  handlePageChange,
  page,
  onClickInfoGroup,
  onClickInfoAntrian,
  onClickEditGroup,
  onClickDeleteGroup,
  onClickPreview,
  onClickPrintFaktur,
  onClickSelesai,
  onClickAddNota,
  onClickProduksi,
  onClickProgress,
  footerComponent,
  data = [],
  isLoading,
  setGroupId,
}) => {
  const dispatch = useDispatch();
  const { role, user_id } = useSelector((state) => state.auth);

  const getDataStatus = useGetCachedData({ queryKey: "status" });

  const [statusProduksi, setStatusProduksi] = useState("");
  const [printLoading, setPrintLoading] = useState(false);

  const handleLoadingPrint = () => {
    setPrintLoading(true);
    setTimeout(() => {
      setPrintLoading(false);
    }, 1000);
  };

  useEffect(() => {
    dispatch(updateLoading(printLoading));
  }, [dispatch, printLoading]);

  // lawasssss
  const theme = useTheme();

  useEffect(() => {
    if (getDataStatus) {
      if (getDataStatus.data) {
        const getStatusProduksi = getDataStatus.data.default.find(
          (status) => status.kode === "S03"
        );
        setStatusProduksi(getStatusProduksi.kode);
      }
    }
  }, [getDataStatus]);

  // collapse-expand
  const [checked, setChecked] = useState(false);

  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    const filterData = data.filter((item) => {
      const matchesStatus =
        filterStatus === "All" || item.status.id === filterStatus;

      const matchesPriority =
        filterPriority === "All" || item.prioritas === filterPriority;

      const matchesSearch =
        (item.keterangan &&
          item.keterangan.toLowerCase().includes(search.toLowerCase())) ||
        item.nota.toLowerCase().includes(search.toLowerCase()) ||
        item.group.nota.some((notaItem) =>
          notaItem.nota.toLowerCase().includes(search.toLowerCase())
        ) ||
        item.group.nota.some((notaItem) =>
          notaItem.nama.toLowerCase().includes(search.toLowerCase())
        );

      return matchesStatus && matchesPriority && matchesSearch;
    });
    setFilteredData(filterData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, filterStatus, filterPriority, data]);

  const offset = (currentPage - 1) * pageSize;
  const currentPageData = filteredData.slice(offset, offset + pageSize);

  const [isInitialized, setIsInitialized] = useState(false);
  useEffect(() => {
    // Initialize first two items as checked=true if data is available and not yet initialized
    if (currentPageData.length > 0 && !isInitialized) {
      const initialCheckedState = currentPageData.reduce(
        (acc, group, index) => {
          if (index < 2) {
            acc[group.nota] = true; // Set first two items to true
          }
          return acc;
        },
        {}
      );
      setChecked(initialCheckedState);
      setIsInitialized(true); // Mark as initialized
    }
  }, [currentPageData, isInitialized]);

  const handleChange = (nota) => {
    setChecked((prev) => ({
      ...prev,
      [nota]: !prev[nota],
    }));
  };

  return (
    <>
      {currentPageData.length > 0 ? (
        currentPageData.map((group) => (
          <Box
            key={group.id}
            sx={{
              flexGrow: 1,
              boxShadow: "2px 2px 5px #cbcbcb",
              backgroundColor: "#fff",
              padding: "1rem",
              borderRadius: "5px",
              marginBottom: "1.5rem",
            }}
            elevation={3}
          >
            <Grid
              container
              spacing={2}
              sx={{ position: "relative", textAlign: "center" }}
            >
              {/* Card 2/3 */}
              <Grid item xs={8}>
                <Item
                  sx={{
                    backgroundColor: group.prioritas ? "#C74A14" : "#7397AB",
                    color: "#fff",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingLeft: "1rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {group.produksi.length > 0 && (
                      <AvatarGroup sx={{ margin: "0 0.5rem 0 0" }}>
                        {group.produksi.map((produksi, index) => (
                          <Tooltip title={produksi.nama} key={index}>
                            <Avatar
                              alt={produksi.nama}
                              sx={{
                                bgcolor: produksi.color,
                                width: 30,
                                height: 30,
                              }}
                              src={
                                produksi.photo
                                  ? `${config.apiUrl}/storage/user/${produksi.photo}`
                                  : undefined
                              }
                            >
                              {produksi.nama.charAt(0)}
                            </Avatar>
                          </Tooltip>
                        ))}
                      </AvatarGroup>
                    )}
                    <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>
                      {group.nota}
                    </Typography>
                  </div>
                  <LoadingProgress
                    progress={group.status.progress}
                    barWidth={"50%"}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>
                      {group.prioritas ? "Prioritas" : "Reguler"}
                    </Typography>
                    <IconButton
                      onClick={() => onClickInfoGroup(group)}
                      size="small"
                      aria-label="info"
                      sx={{ color: "#fff" }}
                    >
                      <InfoIcon />
                    </IconButton>
                  </div>
                </Item>
              </Grid>

              {/* Card 1/3 */}
              <Grid item xs={4}>
                <Item
                  sx={{
                    backgroundColor: group.status.warna,
                    color: "#fff",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingLeft: "1rem",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                >
                  <Typography
                    overflow={"hidden"}
                    textOverflow={"ellipsis"}
                    whiteSpace={"nowrap"}
                  >
                    {group.status.status}
                  </Typography>
                  {role === "admin_produksi" &&
                  page === "antrian_group" &&
                  statusProduksi === group.status.kode ? (
                    <Button
                      onClick={() => onClickProduksi(group)}
                      startIcon={
                        <FireIcon
                          sx={{
                            fontSize: "16px !important",
                            borderRadius: "50%",
                            backgroundColor: "#fff",
                            color: group.status.warna,
                          }}
                        />
                      }
                      variant="outlined"
                      sx={{
                        fontSize: "10px",
                        borderRadius: "5px",
                        padding: "2px 10px",
                        borderColor: "rgba(255, 255, 255, 0.8)",
                        color: "rgba(255, 255, 255, 0.8)",
                        "&:hover": {
                          backgroundColor: "rgba(255, 255, 255, 0.04)",
                          borderColor: "rgba(255, 255, 255, 1)",
                          color: "rgba(255, 255, 255, 1)",
                        },
                        "& .MuiButton-startIcon": {
                          marginRight: "4px",
                        },
                      }}
                    >
                      Produksi
                    </Button>
                  ) : role === "admin_produksi" && page === "dikerjakan" ? (
                    <Button
                      onClick={() => onClickProgress(group)}
                      startIcon={
                        <EditIcon
                          sx={{
                            fontSize: "16px !important",
                            borderRadius: "50%",
                            padding: "2px",
                            backgroundColor: "#fff",
                            color: group.status.warna,
                          }}
                        />
                      }
                      variant="outlined"
                      sx={{
                        fontSize: "10px",
                        borderRadius: "5px",
                        padding: "2px 10px",
                        borderColor: "rgba(255, 255, 255, 0.8)",
                        color: "rgba(255, 255, 255, 0.8)",
                        "&:hover": {
                          backgroundColor: "rgba(255, 255, 255, 0.04)",
                          borderColor: "rgba(255, 255, 255, 1)",
                          color: "rgba(255, 255, 255, 1)",
                        },
                        "& .MuiButton-startIcon": {
                          marginRight: "4px",
                        },
                      }}
                    >
                      Progress
                    </Button>
                  ) : role === "penata" ? (
                    <Button
                      onClick={() => onClickAddNota(group)}
                      startIcon={
                        <AddIcon
                          sx={{
                            fontSize: "16px !important",
                          }}
                        />
                      }
                      variant="outlined"
                      sx={{
                        fontSize: "10px",
                        borderRadius: "5px",
                        padding: "2px 10px",
                        borderColor: "rgba(255, 255, 255, 0.8)",
                        color: "rgba(255, 255, 255, 0.8)",
                        "&:hover": {
                          backgroundColor: "rgba(255, 255, 255, 0.04)",
                          borderColor: "rgba(255, 255, 255, 1)",
                          color: "rgba(255, 255, 255, 1)",
                        },
                        "& .MuiButton-startIcon": {
                          marginRight: "4px",
                        },
                      }}
                    >
                      Nota
                    </Button>
                  ) : null}
                </Item>
              </Grid>

              {/* Data Collapse */}
              <Grid item xs={12}>
                <Collapse in={checked[group.nota]}>
                  <Grid container spacing={2}>
                    {group.group.nota.length > 0 ? (
                      <>
                        {/* Total Antrian */}
                        <Grid item xs={4}>
                          <TotalAntrian
                            nota={group.group.nota}
                            onClickInfoAntrian={() => onClickInfoAntrian(group)}
                            status={group.status.id}
                          />
                        </Grid>

                        {/* Total Order */}
                        <Grid item xs={4}>
                          <TotalOrder size={group.group.size} />
                        </Grid>

                        {/* Total tambahan */}
                        <Grid item xs={4}>
                          <TotalTambahan additional={group.group.additional} />
                        </Grid>
                      </>
                    ) : (
                      // Data Kosong
                      <Grid
                        item
                        xs={12}
                        position={"relative"}
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        marginBottom={1}
                      >
                        <BlackIcon
                          sx={{
                            color: "rgba(201, 201, 201, 0.69)",
                            fontSize: "70px",
                          }}
                        />
                        <Typography
                          fontSize={12}
                          color={"#B1B1B1"}
                          fontWeight={700}
                        >
                          Data antrian kosong !
                        </Typography>
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      {group.keterangan && (
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            sx={{
                              textAlign: "start",
                              color: "#008DD2",
                              fontSize: 10,
                              [theme.breakpoints.up("xl")]: {
                                fontSize: 12,
                              },
                            }}
                          >
                            <b>Note</b> : {group.keterangan}
                          </Grid>
                        </Grid>
                      )}
                    </Grid>

                    {/* Action Footer */}
                    <Grid item xs={12} sx={{ paddingTop: "0.3rem !important" }}>
                      <Item
                        sx={{
                          backgroundColor: "#F2F2F2",
                          borderRadius: "5px",
                          color: "#797979",
                          boxShadow: "none",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: 10,
                          }}
                        >
                          <Typography
                            style={{ padding: "0 0.5rem", fontSize: 10 }}
                          >
                            Created by : {group.user.nama} |{" "}
                            {dateFormat(group.tanggal, "simple", "-")} |{" "}
                            {dateFormatter({
                              date: new Date(group.tanggal),
                              timesOnly: true,
                            })}
                          </Typography>
                          {(role === "penata" || role === "root") && (
                            <>
                              <Button
                                onClick={() => onClickEditGroup(group)}
                                startIcon={
                                  <EditIcon
                                    sx={{
                                      fontSize: "21px",
                                      color: "#fff",
                                      backgroundColor: "rgba(255, 143, 40, 1)",
                                      padding: "4px",
                                      borderRadius: "50%",
                                    }}
                                  />
                                }
                                sx={{
                                  fontSize: "10px",
                                  fontWeight: 700,
                                  color: "rgba(255, 143, 40, 1)",
                                  "&:disabled": {
                                    color: "rgba(255, 143, 40, 1)",
                                    opacity: "0.38",
                                  },
                                }}
                              >
                                Edit Group
                              </Button>
                              <Button
                                onClick={() => onClickDeleteGroup(group)}
                                startIcon={
                                  <DeleteIcon
                                    sx={{
                                      fontSize: "21px",
                                      color: "#fff",
                                      backgroundColor: "rgba(255, 0, 0, 1)",
                                      padding: "4px",
                                      borderRadius: "50%",
                                    }}
                                  />
                                }
                                sx={{
                                  fontSize: "10px",
                                  fontWeight: 700,
                                  color: "#FF0000",
                                  "&:disabled": {
                                    color: "#FF0000",
                                    opacity: "0.38",
                                  },
                                }}
                                disabled={
                                  (role !== "penata" || role !== "root") &&
                                  user_id !== group.user.id
                                }
                              >
                                Delete Group
                              </Button>
                            </>
                          )}
                        </div>
                        <div>
                          <Button
                            onClick={() => onClickPreview(group)}
                            startIcon={<ImageIcon />}
                            sx={{
                              color: "#2FA9EE",
                              fontSize: 10,
                              "&:disabled": {
                                opacity: "0.38",
                                color: "#2FA9EE",
                              },
                            }}
                            disabled={group.gambar ? false : true}
                          >
                            Preview
                          </Button>
                          {role !== "desainer" &&
                            (page === "manage_group" ||
                              page === "antrian_group" ||
                              page === "dikerjakan") && (
                              <Button
                                onClick={() => {
                                  handleLoadingPrint();
                                  onClickPrintFaktur(group);
                                  setGroupId(group.id);
                                }}
                                startIcon={<PrintIcon />}
                                sx={{
                                  color: "#2FA9EE",
                                  fontSize: 10,
                                  "&:disabled": {
                                    opacity: "0.38",
                                    color: "#2FA9EE",
                                  },
                                }}
                                disabled={
                                  group.group.nota.length > 0 ? false : true
                                }
                              >
                                Print Faktur
                              </Button>
                            )}
                          {role === "admin_produksi" &&
                          page === "dikerjakan" ? (
                            <Button
                              onClick={() => onClickSelesai(group)}
                              variant="contained"
                              startIcon={<DoneIcon />}
                              sx={{
                                backgroundColor: "#2FA9EE",
                                fontSize: 10,
                                padding: "4px 16px",
                                borderRadius: "6px",
                                "&:hover": {
                                  backgroundColor: "#1b8cce",
                                },
                              }}
                            >
                              Selesai
                            </Button>
                          ) : null}
                        </div>
                      </Item>
                    </Grid>
                  </Grid>
                </Collapse>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  paddingTop: checked[group.nota] ? "" : "0 !important",
                }}
              >
                <CollapseButton
                  onClick={() => handleChange(group.nota)}
                  checked={checked[group.nota]}
                  expandLabel="Expand"
                  collapseLabel="Collapse"
                />
              </Grid>
            </Grid>
          </Box>
        ))
      ) : (
        <Box
          sx={{
            boxShadow: "2px 2px 5px #cbcbcb",
            backgroundColor: "#fff",
            paddingX: isLoading ? "2rem" : "1rem",
            paddingY: isLoading ? "2rem" : { md: "4rem", xl: "15rem" },
            borderRadius: "5px",
            marginBottom: "1.5rem",
            alignItems: "start",
          }}
          elevation={3}
        >
          {isLoading ? (
            <Grid container spacing={3}>
              <Grid item xs={8}>
                <Skeleton
                  variant="rounded"
                  sx={{ height: { md: 50, xl: 100 } }}
                />
              </Grid>
              <Grid item xs={4}>
                <Skeleton
                  variant="rounded"
                  sx={{ height: { md: 50, xl: 100 } }}
                />
              </Grid>
              <Grid item xs={4}>
                <Skeleton
                  variant="rounded"
                  sx={{ height: { md: 120, xl: 250 } }}
                />
              </Grid>
              <Grid item xs={4}>
                <Skeleton
                  variant="rounded"
                  sx={{ height: { md: 120, xl: 250 } }}
                />
              </Grid>
              <Grid item xs={4}>
                <Skeleton
                  variant="rounded"
                  sx={{ height: { md: 120, xl: 250 } }}
                />
              </Grid>
              <Grid item xs={12} sx={{ display: { md: "none", xl: "block" } }}>
                <Skeleton
                  variant="rounded"
                  sx={{ height: { md: 130, xl: 145 } }}
                />
              </Grid>
            </Grid>
          ) : (
            <EmptyDataOverlay />
          )}
        </Box>
      )}

      {Math.ceil(filteredData.length / pageSize) > 1 && (
        <Box marginTop={-2}>
          <Paper
            elevation={0}
            sx={{
              display: "flex",
              background:
                "linear-gradient(90deg, rgba(238,174,202,0) 0%, rgba(255,255,255,1) 20%, rgba(255,255,255,1) 80%, rgba(255,255,255,0) 100%)",
              flexGrow: 1,
              justifyContent: "center",
              padding: 1,
              boxShadow: "0px 0px 40px -27px #f1f6f8 inset",
            }}
          >
            <Pagination
              count={Math.ceil(filteredData.length / pageSize)}
              page={currentPage}
              onChange={(_, value) => handlePageChange(value)}
              shape="rounded"
              // variant="outlined"
              color="primary"
              size="small"
              sx={{
                "& .MuiButtonBase-root.MuiPaginationItem-root.Mui-selected": {
                  backgroundColor: "#3D85AE",
                },
              }}
            />
          </Paper>
        </Box>
      )}
      <Box
        sx={{
          marginTop: Math.ceil(filteredData.length / pageSize) > 1 ? 1 : 3,
        }}
      >
        {footerComponent && footerComponent()}
      </Box>
    </>
  );
};

export default LayoutGroup;
export { AddGroup };
