import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button } from "@mui/material";
import {
  AssignmentTurnedIn as SelesaiIcon,
  Settings as GearIcon,
  LocalShipping as PengirimanIcon,
} from "@mui/icons-material";
import { CustomDatagrid } from "../../../../components/Layout/Content";
import {
  SET_COLUMN,
  SET_FILTER,
  SET_SORT,
  SET_PAGE,
  SET_PAGE_SIZE,
  SET_ROW_GROUP,
  SET_AGGREGATION,
  SET_PINNED_COLUMN,
  SET_ROW_SELECTION,
} from "../domain/DikirimSlice";
import { Config } from "../repository";
import FooterCardDikirim from "./TabelDikirim/FooterCardDikirim";
import ActionButton from "./TabelDikirim/ActionButton";
import { EventHandlers } from "../repository";

const TabelDikirim = ({ data, isLoading, jenisOrder, sourceOrder }) => {
  const dispatch = useDispatch();

  const {
    page,
    pageSize,
    filterModel,
    sortModel,
    columnModel,
    rowGroup,
    aggregationModel,
    pinnedModel,
    rowSelectionModel,
  } = useSelector((state) => state.checkoutDikirim);

  const { role } = useSelector((state) => state.auth);

  const { setting } = useSelector((state) => state.data);

  const statusOptions = Array.from(
    new Set(data.map((item) => item.status.status))
  );

  const jenisOrderOptions = Array.from(
    new Set(jenisOrder.filter((jenis) => jenis.status).map((item) => item.name))
  );

  const sourceOrderOptions = Array.from(
    new Set(
      sourceOrder.filter((source) => source.status).map((item) => item.name)
    )
  );

  const columns = Config.column.map((e) => {
    if (e.field === "status") {
      return {
        ...e,
        valueOptions: statusOptions,
      };
    }
    if (e.field === "jenis_order") {
      return {
        ...e,
        valueOptions: jenisOrderOptions,
      };
    }
    if (e.field === "source_order") {
      return {
        ...e,
        valueOptions: sourceOrderOptions,
      };
    }
    if (e.field === "preview") {
      return {
        ...e,
        getActions: (params) => {
          if (typeof params.id === "number") {
            return ActionButton.getActionPreview({
              role,
              params,
              dispatch,
            });
          }
          return [];
        },
      };
    }
    if (e.field === "actions") {
      return {
        ...e,
        getActions: (params) => {
          if (typeof params.id === "number") {
            const statusDeleteAntrian = setting.find(
              (item) => item.rule === "DELETE_ANTRIAN"
            );
            return ActionButton.getActionOpsi({
              role,
              params,
              dispatch,
              statusDeleteAntrian: statusDeleteAntrian.value === "false",
            });
          }
          return [];
        },
      };
    }
    return e;
  });

  if (role === "root" || role === "customer_service") {
    const indexToInsertAfter = columns.findIndex((e) => e.field === "actions");

    const statusEditAntrian = setting.find(
      (item) => item.rule === "EDIT_ANTRIAN"
    );

    const statusEditSize = setting.find((item) => item.rule === "EDIT_SIZE");

    columns.splice(indexToInsertAfter, 0, {
      headerName: "Edit",
      disableClickEventBubbling: true,
      field: "edit",
      type: "actions",
      sortable: false,
      filterable: false,
      groupable: false,
      aggregable: false,
      pinnable: false,
      disableColumnMenu: true,
      width: 60,
      getActions: (params) => {
        if (typeof params.id === "number") {
          return ActionButton.getActionEdit({
            role,
            params,
            dispatch,
            statusEditAntrian:
              role !== "root" ? statusEditAntrian.value === "false" : false,
            statusEditSize:
              role !== "root" ? statusEditSize.value === "false" : false,
          });
        }
        return [];
      },
    });
  }

  if (role === "root" || role === "customer_service") {
    const indexToInsertAfter = columns.findIndex(
      (e) => e.field === "metode_pembayaran"
    );

    if (indexToInsertAfter !== -1) {
      if (role === "root") {
        columns.splice(indexToInsertAfter + 1, 0, {
          field: "actionProgress",
          headerName: "Progress",
          minWidth: 150,
          hideable: false,
          sortable: false,
          filterable: false,
          groupable: false,
          aggregable: false,
          pinnable: true,
          align: "center",
          headerAlign: "center",
          disableColumnMenu: false,
          renderHeader: () => <GearIcon sx={{ color: "#797979" }} />,
          renderCell: (params) => {
            if (typeof params.id === "number") {
              return (
                <Button
                  size="small"
                  startIcon={<GearIcon sx={{ color: "#B4B4B4" }} />}
                  variant="outlined"
                  color="primary"
                  sx={{
                    color: "#B4B4B4",
                    borderColor: "#B4B4B4",
                    borderWidth: "2px",
                    borderRadius: "15px",
                    padding: "2px 15px",
                    fontSize: "10px",
                    "&:hover": {
                      borderWidth: "2px",
                      color: "#000",
                      borderColor: "#000",
                      "& .MuiSvgIcon-root": { color: "#000" },
                    },
                  }}
                  onClick={() => {
                    EventHandlers.handleProgressModal({
                      dispatch,
                      modal: {
                        id: params.id,
                        open: true,
                        title: "Edit Progress !",
                      },
                    });
                  }}
                >
                  Progress
                </Button>
              );
            }
            return [];
          },
        });
      }

      if (role === "customer_service") {
        columns.splice(indexToInsertAfter + 2, 0, {
          field: "actionSelesai",
          headerName: "Selesai",
          minWidth: 150,
          hideable: false,
          sortable: false,
          filterable: false,
          groupable: false,
          aggregable: false,
          pinnable: true,
          align: "center",
          headerAlign: "center",
          disableColumnMenu: false,
          renderHeader: () => <SelesaiIcon sx={{ color: "#797979" }} />,
          renderCell: (params) => {
            if (typeof params.id === "number") {
              return (
                <Button
                  size="small"
                  startIcon={<SelesaiIcon sx={{ color: "#CD8E54" }} />}
                  variant="outlined"
                  color="primary"
                  sx={{
                    borderWidth: "2px",
                    borderRadius: 15,
                    padding: "2px 15px",
                    fontSize: "10px",
                    "&:hover": {
                      borderWidth: "2px",
                    },
                  }}
                  onClick={() => {
                    EventHandlers.handleSelesaiModal({
                      dispatch,
                      modal: {
                        id: params.id,
                        open: true,
                        title: "Selesai Antrian !",
                      },
                    });
                  }}
                >
                  Selesai
                </Button>
              );
            }
            return [];
          },
        });
      }
    }
  }

  return (
    <>
      <CustomDatagrid
        {...{
          rows: data,
          columns,
          isLoading: isLoading,
          columnModel,
          page,
          pageSize,
          filterModel,
          sortModel,
          rowGroup,
          aggregationModel,
          pinnedModel,
          SET_COLUMN: SET_COLUMN,
          SET_PAGE: SET_PAGE,
          SET_PAGE_SIZE: SET_PAGE_SIZE,
          SET_FILTER: SET_FILTER,
          SET_SORT: SET_SORT,
          SET_ROW_GROUP,
          SET_AGGREGATION,
          SET_PINNED_COLUMN,
        }}
        withNumberColumn={true}
        getRowClassName={(e) => e.row.prioritas && `datagrid-prioritas`}
        footerComponent={({ finalData }) => {
          return <FooterCardDikirim data={finalData} isLoading={isLoading} />;
        }}
        rowSelectionModel={rowSelectionModel}
        checkboxSelection={true}
        SET_ROW_SELECTION={SET_ROW_SELECTION}
        customToolbar={
          <Box sx={{ p: 1 }}>
            <Button
              size="small"
              startIcon={<PengirimanIcon sx={{ color: "#2FA9EE" }} />}
              variant="outlined"
              sx={{
                borderRadius: 15,
                textTransform: "capitalize",
                padding: "2px 15px",
                color: "#2FA9EE",
                fontSize: "10px",
                "&:hover": {
                  borderColor: "#2FA9EE",
                },
              }}
              disabled={rowSelectionModel.length < 1}
              onClick={() => {
                EventHandlers.handleMassPengirimanModal({
                  dispatch,
                  modal: true,
                });
              }}
            >
              Antrian Pengiriman Selected ({rowSelectionModel.length})
            </Button>
          </Box>
        }
      />
    </>
  );
};

TabelDikirim.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  jenisOrder: PropTypes.array,
  sourceOrder: PropTypes.array,
};

export default TabelDikirim;
