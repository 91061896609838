import { Config, EventHandlers } from ".";
import {
  useCreatingData,
  useFetchingData,
  useDeletingData,
  useUpdatingData,
} from "../../../../hooks";
export class Api {
  static read = () => {
    return useFetchingData({
      endpoint: `${Config.variable.endpoint}/dikirim`,
    });
  };

  static searchKecamatan = (props) => {
    const { search } = props;
    return useFetchingData({
      endpoint: "kecamatan",
      params: { params: { search } },
      enabled: search.length > 2 ? true : false,
      gcTime: 0,
    });
  };

  static updateDetail = ({ dispatch, handleClose, form, socket }) => {
    return useUpdatingData({
      endpoint: `antrian/edit/detail`,
      invalidated: [`${Config.variable.endpoint}/dikirim`],
      onSuccess: () => {
        EventHandlers.handleRefetchStatus({ dispatch, refetch_status: true });
        handleClose();
        if (socket) {
          socket.emit("checkout_dikirim");
        }
      },
      onError: (error) => {
        if (error.errors) {
          for (const key in error.errors) {
            form.setError(key, {
              type: "manual",
              message: error.errors[key].join(" | "),
            });
          }
        }
      },
    });
  };

  static updateOrder = ({ dispatch, handleClose, form, socket }) => {
    return useUpdatingData({
      endpoint: `antrian/edit/order`,
      invalidated: [`${Config.variable.endpoint}/dikirim`],
      onSuccess: () => {
        EventHandlers.handleRefetchStatus({ dispatch, refetch_status: true });
        handleClose();
        if (socket) {
          socket.emit("checkout_dikirim");
        }
      },
      onError: (error) => {
        if (error.errors) {
          for (const key in error.errors) {
            form.setError(key, {
              type: "manual",
              message: error.errors[key].join(" | "),
            });
          }
        }
      },
    });
  };

  static detailAntrianEdit = (props) => {
    const { modal } = props;
    return useFetchingData({
      endpoint: `antrian/edit/${modal.id ? modal.id : ""}`,
      enabled: modal.open,
      gcTime: 0,
    });
  };

  static detailAntrianSize = (props) => {
    const { modal } = props;
    return useFetchingData({
      endpoint: `antrian/size/${modal.id ? modal.id : ""}`,
      enabled: modal.open,
      gcTime: 0,
    });
  };

  static checkoutSelesai = ({ handleClose, dispatch, socket }) => {
    return useUpdatingData({
      endpoint: `${Config.variable.endpoint}/selesai`,
      invalidated: [`${Config.variable.endpoint}/dikirim`],
      onSuccess: () => {
        EventHandlers.handleRefetchStatus({ dispatch, refetch_status: true });
        handleClose();
        if (socket) {
          socket.emit("checkout_dikirim");
          socket.emit("rekap_nota");
          socket.emit("dashboard_business_progress");
          socket.emit("pendapatan");
        }
      },
    });
  };

  static progressAntrian = ({ handleClose, dispatch, socket }) => {
    return useUpdatingData({
      endpoint: `antrian/progress`,
      invalidated: [`${Config.variable.endpoint}/siap_kirim`],
      onSuccess: () => {
        EventHandlers.handleRefetchStatus({ dispatch, refetch_status: true });
        handleClose();
        if (socket) {
          socket.emit("checkout_dikirim");
        }
      },
    });
  };

  static printAntrian = (props) => {
    const { modal } = props;
    return useFetchingData({
      endpoint: `antrian/print/${modal.id ? modal.id : ""}`,
      enabled: modal.open,
      gcTime: 0,
    });
  };

  static deleteAntrian = ({ handleClose, dispatch, socket }) => {
    return useDeletingData({
      endpoint: `antrian`,
      invalidated: [`${Config.variable.endpoint}/dikirim`],
      onSuccess: () => {
        EventHandlers.handleRefetchStatus({ dispatch, refetch_status: true });
        handleClose();
        if (socket) {
          socket.emit("checkout_dikirim");
        }
      },
    });
  };

  static uploadGambarAntrian = ({
    setProgress,
    id,
    handleClose,
    dispatch,
    socket,
  }) => {
    return useCreatingData({
      endpoint: `antrian/gambar/${id}`,
      invalidated: [`${Config.variable.endpoint}/dikirim`],
      request: {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params: { _method: "PUT" },
        onUploadProgress: (progressEvent) => {
          const progressPercentage = Math.round(
            (progressEvent.loaded / (progressEvent.total ?? 1)) * 100
          );
          setProgress(progressPercentage);
        },
      },
      onSuccess: () => {
        EventHandlers.handleRefetchStatus({ dispatch, refetch_status: true });
        handleClose();
        if (socket) {
          socket.emit("checkout_dikirim");
        }
      },
      onError: () => {
        return;
      },
    });
  };

  static deleteGambarAntrian = ({ modal, dispatch, socket }) => {
    return useDeletingData({
      endpoint: "antrian/gambar",
      invalidated: [
        `${Config.variable.endpoint}/dikirim`,
        `antrian/gambar/${modal.id ? modal.id : ""}`,
      ],
      onSuccess: () => {
        EventHandlers.handleRefetchStatus({ dispatch, refetch_status: true });
        if (socket) {
          socket.emit("checkout_dikirim");
        }
        return;
      },
    });
  };

  static detailAntrian = (props) => {
    const { modal } = props;
    return useFetchingData({
      endpoint: `antrian/${modal.id ? modal.id : ""}`,
      enabled: modal.open,
      gcTime: 0,
    });
  };

  static detailProgress = (props) => {
    const { modal } = props;
    return useFetchingData({
      endpoint: `antrian/progress/${modal.id ? modal.id : ""}`,
      enabled: modal.open,
      gcTime: 0,
    });
  };

  static detailAntrianGambar = (props) => {
    const { modal } = props;
    return useFetchingData({
      endpoint: `antrian/gambar/${modal.id ? modal.id : ""}`,
      enabled: modal.open,
      gcTime: 0,
    });
  };

  static personAntrian = ({ handleClose }) => {
    return useUpdatingData({
      endpoint: `antrian/person`,
      invalidated: [`${Config.variable.endpoint}/dikirim`],
      onSuccess: () => {
        handleClose();
      },
    });
  };

  static detailPembayaran = (props) => {
    const { modal } = props;
    return useFetchingData({
      endpoint: `antrian/pembayaran/${modal.id ? modal.id : ""}`,
      enabled: modal.open,
      gcTime: 0,
    });
  };

  static updatePembayaran = ({ id, onSuccess, dispatch, socket }) => {
    return useUpdatingData({
      endpoint: `antrian/create/pembayaran`,
      invalidated: [
        `${Config.variable.endpoint}/dikirim`,
        `antrian/pembayaran/${id}`,
      ],
      onSuccess: () => {
        EventHandlers.handleRefetchStatus({ dispatch, refetch_status: true });
        onSuccess();
        if (socket) {
          socket.emit("checkout_dikirim");
          socket.emit("dashboard_last_pembayaran");
        }
      },
      onError: () => {
        return;
      },
    });
  };

  static deletePembayaran = ({ id, handleClose, dispatch, socket }) => {
    return useDeletingData({
      endpoint: `antrian/pembayaran`,
      invalidated: [
        `${Config.variable.endpoint}/dikirim`,
        `antrian/pembayaran/${id}`,
      ],
      onSuccess: () => {
        EventHandlers.handleRefetchStatus({ dispatch, refetch_status: true });
        handleClose();
        if (socket) {
          socket.emit("checkout_dikirim");
        }
      },
    });
  };

  static massPengiriman = ({ socket, dispatch, onSuccess }) => {
    return useCreatingData({
      endpoint: `${Config.variable.endpoint}/kirim/mass`,
      invalidated: [`${Config.variable.endpoint}/dikirim`],
      onSuccess: () => {
        onSuccess();
        EventHandlers.handleRefetchStatus({ dispatch, refetch_status: true });
        if (socket) {
          socket.emit("checkout_dikirim");
        }
        return;
      },
      onError: () => {
        return;
      },
    });
  };
}
