import config from "../../../../config";
import {
  SET_DETAIL_MODAL,
  SET_PEMBAYARAN_MODAL,
  SET_PRINT_MODAL,
  SET_DETAIL_PROGRESS_MODAL,
  SET_UPLOAD_PREVIEW_MODAL,
  SET_SELESAI_MODAL,
  SET_CHANGE_PERSON_MODAL,
  SET_PROGRESS_MODAL,
  SET_HAPUS_ANTRIAN,
  SET_DELETE_PEMBAYARAN_MODAL,
  SET_REFETCH_STATUS,
  SET_EDIT_DETAIL_ORDER,
  SET_EDIT_SIZE_QTY,
  SET_CHANGE_DESIGNER_MODAL,
  SET_MASS_PENGIRIMAN_MODAL,
} from "../domain/DikirimSlice";
import { UPDATE_IMAGE } from "../../../imageDetailSlice";

export class EventHandlers {
  static handlePreview = ({ dispatch, file, title }) => {
    dispatch(
      UPDATE_IMAGE({
        url: `${config.apiUrl}/storage/antrian/${file}`,
        title: title,
      })
    );
  };

  static handleSelesaiModal = ({ dispatch, modal }) => {
    dispatch(SET_SELESAI_MODAL({ modal }));
  };

  static handleChangePersonModal = ({ dispatch, modal }) => {
    dispatch(SET_CHANGE_PERSON_MODAL({ modal }));
  };

  static handleDetailModal = ({ dispatch, modal }) => {
    dispatch(SET_DETAIL_MODAL({ modal }));
  };

  static handleDetailProgressModal = ({ dispatch, modal }) => {
    dispatch(SET_DETAIL_PROGRESS_MODAL({ modal }));
  };

  static handlePrintModal = ({ dispatch, modal }) => {
    dispatch(SET_PRINT_MODAL({ modal }));
  };

  static handleUploadPreviewModal = ({ dispatch, modal }) => {
    dispatch(SET_UPLOAD_PREVIEW_MODAL({ modal }));
  };

  static handleProgressModal = ({ dispatch, modal }) => {
    dispatch(SET_PROGRESS_MODAL({ modal }));
  };

  static handlePembayaranModal = ({ dispatch, modal }) => {
    dispatch(SET_PEMBAYARAN_MODAL({ modal }));
  };

  static handleDeletePembayaran = ({ dispatch, modal }) => {
    dispatch(SET_DELETE_PEMBAYARAN_MODAL({ modal }));
  };

  static handleHapusAntrian = ({ dispatch, modal }) => {
    dispatch(SET_HAPUS_ANTRIAN({ modal }));
  };

  static handleRefetchStatus = ({ dispatch, refetch_status }) => {
    dispatch(SET_REFETCH_STATUS({ refetch_status }));
  };

  static handleEditDetailOrderModal = ({ dispatch, modal }) => {
    dispatch(SET_EDIT_DETAIL_ORDER({ modal }));
  };

  static handleEditSizeQtyModal = ({ dispatch, modal }) => {
    dispatch(SET_EDIT_SIZE_QTY({ modal }));
  };

  static handleChangeDesignerModal = ({ dispatch, modal }) => {
    dispatch(SET_CHANGE_DESIGNER_MODAL({ modal }));
  };

  static handleMassPengirimanModal = ({ dispatch, modal }) => {
    dispatch(SET_MASS_PENGIRIMAN_MODAL({ modal }));
  };
}
