import { Chip, Divider, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import logoWave from "../../../../../assets/icon/logo/waveprint.svg";
import React, { useEffect, useState } from "react";
import config from "../../../../../config";
import QRCode from "react-qr-code";
import md5 from "md5";
import { dateFormat } from "../../../../controls";

export const PrintFaktur = React.forwardRef((props, ref) => {
  const { data } = props;
  const { setting } = useSelector((state) => state.data);
  const [printedOn, setPrintedOn] = useState(null);
  const [pengaturan, setPengaturan] = useState({
    nama: "",
    alamat: "",
    no_hp: "",
    logo: "",
  });

  const [dataDetail, setDataDetail] = useState([]);

  useEffect(() => {
    if (data?.nota) {
      setDataDetail({ ...data });
      const date = new Date();
      setPrintedOn(
        `${date.getDate()}-${
          date.getMonth() + 1
        }-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`
      );
    }
  }, [data]);

  useEffect(() => {
    if (setting.length) {
      const nama = setting.find((e) => e.rule === "NAMA_TOKO");
      const alamat = setting.find((e) => e.rule === "ALAMAT");
      const no_hp = setting.find((e) => e.rule === "NO_HP");
      const logo = setting.find((e) => e.rule === "LOGO_NOTA");
      if (nama) {
        setPengaturan((old) => ({ ...old, nama: nama.value }));
      }
      if (alamat) {
        setPengaturan((old) => ({ ...old, alamat: alamat.value }));
      }
      if (no_hp) {
        setPengaturan((old) => ({ ...old, no_hp: no_hp.value }));
      }
      if (logo) {
        setPengaturan((old) => ({ ...old, logo: logo.value }));
      }
    }
  }, [setting]);

  return (
    <div
      ref={ref}
      style={{
        padding: "1rem",
        color: "black",
        fontSize: 12,
        fontFamily: "PrintFont",
      }}
    >
      <center style={{ paddingBottom: 5 }}>
        {pengaturan.logo && (
          <img
            src={config.apiUrl + "/storage/logo/" + pengaturan.logo}
            alt="Logo"
            width={"40px"}
            height={"auto"}
          />
        )}
        <Typography sx={{ fontSize: 12, fontWeight: 700 }}>
          {pengaturan.nama}
        </Typography>
        <Typography sx={{ fontSize: 9 }}>{pengaturan.alamat}</Typography>
        <Typography sx={{ fontSize: 9 }}>{pengaturan.no_hp}</Typography>
      </center>
      <Divider sx={{ borderBottomWidth: 1, bgcolor: "black" }} />
      <table
        width={"100%"}
        style={{
          paddingTop: 10,
          paddingBottom: 5,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <tbody style={{ width: "80%" }}>
          <tr>
            <td
              width={"45%"}
              align={"left"}
              style={{ alignContent: "baseline" }}
            >
              <b>No. Faktur</b>
            </td>
            <td
              width={"5%"}
              align={"left"}
              style={{ alignContent: "baseline" }}
            >
              :
            </td>
            <td align={"left"} style={{ alignContent: "baseline" }}>
              <b>{dataDetail.nota}</b>
            </td>
          </tr>
          <tr>
            <td
              width={"45%"}
              align={"left"}
              style={{ alignContent: "baseline" }}
            >
              Keterangan
            </td>
            <td
              width={"5%"}
              align={"left"}
              style={{ alignContent: "baseline" }}
            >
              :
            </td>
            <td align={"left"} style={{ alignContent: "baseline" }}>
              {dataDetail.keterangan}
            </td>
          </tr>
          <tr>
            <td
              width={"45%"}
              align={"left"}
              style={{ alignContent: "baseline" }}
            >
              Total Antrian
            </td>
            <td
              width={"5%"}
              align={"left"}
              style={{ alignContent: "baseline" }}
            >
              :
            </td>
            <td align={"left"} style={{ alignContent: "baseline" }}>
              {dataDetail?.group?.total_nota} Nota
            </td>
          </tr>
          <tr>
            <td
              width={"45%"}
              align={"left"}
              style={{ alignContent: "baseline" }}
            >
              Total Order
            </td>
            <td
              width={"5%"}
              align={"left"}
              style={{ alignContent: "baseline" }}
            >
              :
            </td>
            <td align={"left"} style={{ alignContent: "baseline" }}>
              {dataDetail?.group?.total_keterangan} Pcs
            </td>
          </tr>
          <tr>
            <td
              width={"45%"}
              align={"left"}
              style={{ alignContent: "baseline" }}
            >
              Total Additional
            </td>
            <td
              width={"5%"}
              align={"left"}
              style={{ alignContent: "baseline" }}
            >
              :
            </td>
            <td align={"left"} style={{ alignContent: "baseline" }}>
              {dataDetail?.group?.total_additional} Pcs
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td rowSpan={5}>
              {dataDetail.nota && (
                <QRCode value={md5(dataDetail.nota)} size={100} />
              )}
            </td>
          </tr>
        </tbody>
      </table>
      <Divider
        sx={{
          "&::before, &::after": {
            borderBottomWidth: 1,
            borderTopWidth: 1,
            borderColor: "black",
          },
        }}
      >
        <Chip
          label="Detail Order"
          sx={{
            backgroundColor: "#000",
            color: "#fff",
            height: "100%",
            fontSize: "8px",
          }}
        />
      </Divider>
      <table
        width="100%"
        style={{
          paddingTop: 5,
          paddingBottom: 8,
          borderCollapse: "collapse",
        }}
      >
        <tbody>
          {dataDetail?.group?.nota.map((rgroup, indexGroup) => (
            <React.Fragment key={indexGroup}>
              {/* Header Nota */}
              <tr>
                <td colSpan="5" style={{ paddingTop: "10px" }}>
                  <Chip
                    label={
                      <>
                        <b>{rgroup.nota}</b>
                        {" | " + dateFormat(rgroup.check_in) + " | "}
                        <b>{rgroup.nama}</b>
                      </>
                    }
                    sx={{
                      paddingY: "5px",
                      borderRadius: 0,
                      backgroundColor: "#cccccc",
                      color: "#0a0a0a",
                      height: "100%",
                      fontSize: "10px",
                    }}
                  />
                </td>
              </tr>

              {/* Header Kolom */}
              <tr style={{ fontWeight: "bold", backgroundColor: "#f0f0f0" }}>
                {["Size", "Nama", "Nomor", "Warna Celana", "Additional"].map(
                  (header, index) => (
                    <th
                      key={index}
                      style={{ border: "1px solid black", padding: "5px" }}
                    >
                      {header}
                    </th>
                  )
                )}
              </tr>

              {/* Data */}
              {rgroup.size.map((item, indexItem) => (
                <React.Fragment key={indexItem}>
                  {item.keterangan.map((detail, rowIndex) => {
                    return (
                      <tr key={rowIndex}>
                        {/* Rowspan hanya di baris pertama */}
                        {rowIndex === 0 && (
                          <td
                            rowSpan={item.keterangan.length}
                            align="center"
                            style={{
                              border: "1px solid black",
                              padding: "5px",
                              fontWeight: "bold",
                              backgroundColor: "#f0f0f0",
                            }}
                          >
                            {item.nama}
                          </td>
                        )}
                        {/* Nama */}
                        <td
                          style={{ border: "1px solid black", padding: "5px" }}
                        >
                          {detail.nama || "-"}
                        </td>
                        {/* No */}
                        <td
                          style={{
                            border: "1px solid black",
                            padding: "5px",
                            textAlign: "center",
                          }}
                        >
                          {detail.nomor || "-"}
                        </td>
                        {/* Warna Celana */}
                        <td
                          style={{
                            border: "1px solid black",
                            padding: "5px",
                            textAlign: "center",
                          }}
                        >
                          {detail["warna celana"]?.trim() || "-"}
                        </td>
                        {/* Additional */}
                        <td
                          style={{
                            border: "1px solid black",
                            padding: "5px",
                            textAlign: "center",
                          }}
                        >
                          {detail.additional.length > 0
                            ? detail.additional
                                .map((add) => add.nama)
                                .join(", ")
                            : "-"}
                        </td>
                      </tr>
                    );
                  })}
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1rem 0 0 0",
        }}
      >
        <Typography sx={{ fontSize: 8 }}>Printed on : {printedOn}</Typography>
        <Typography sx={{ fontSize: 7 }}>
          Powered by{" "}
          <img src={logoWave} alt="logoWave" style={{ width: "30px" }} />
        </Typography>
      </div>
      {dataDetail.gambar ? (
        <div style={{ paddingTop: "1rem" }}>
          <img
            src={config.apiUrl + "/storage/group/" + dataDetail.gambar}
            alt="desain"
            style={{ objectFit: "cover", height: "auto", width: "100%" }}
          />
        </div>
      ) : null}
    </div>
  );
});

export default PrintFaktur;
