import React, { useCallback, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useDispatch, useSelector } from "react-redux";
import { EventHandlers } from "../repository";
import { PrintFaktur } from "../../../../components/Layout/Content/DataGroup/FormDataGroup";

const PrintGroup = ({ dataDetailGroup }) => {
  const componentRef = useRef();
  const dispatch = useDispatch();
  const { printGroup } = useSelector((state) => state.antrianGroup);

  const handleClose = useCallback(() => {
    EventHandlers.handlePrintGroup({
      dispatch,
      modal: {
        id: null,
        open: false,
        title: null,
        data: {
          nota: null,
          keterangan: null,
          antrian: 0,
          size: 0,
          additional: 0,
          list: [],
          gambar: null,
        },
      },
    });
  }, [dispatch]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => handleClose(),
  });

  useEffect(() => {
    if (printGroup.data.nota) {
      setTimeout(() => handlePrint(), 500);
    }
  }, [handlePrint, printGroup.data]);

  return (
    <div style={{ display: "none" }}>
      <PrintFaktur data={dataDetailGroup} ref={componentRef} />
    </div>
  );
};

export default PrintGroup;
