import React from "react";
import {
  Grid,
  Typography,
  Stack,
  Divider,
  Chip,
  IconButton,
} from "@mui/material";
import {
  NumberInput,
  SwitchInput,
  Input,
  MultipleSelect,
  CheckboxInput,
} from "../../../../controls";
import { ChipItems } from "../../../../common";
import {
  Add as AddIcon,
  RemoveCircle as RemoveCircleIcon,
} from "@mui/icons-material";
import { useFieldArray } from "react-hook-form";
import { useSelector } from "react-redux";

const makeID = (length) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

const FormSizeQty = ({ formAntrian, additional, keterangan, isLoading }) => {
  const CheckHarga = ({ harga, hpp }) => {
    let error = [];
    if (hpp === 0) {
      error.push("HPP");
    }
    if (harga === 0) {
      error.push("Harga");
    }

    if (error.length > 0) {
      return (
        <Stack direction={"row"} spacing={1}>
          <Typography color={"#F60000"} fontSize={9}>
            N/A :
          </Typography>
          {error.map((item, index) => (
            <ChipItems
              key={index}
              label={item}
              sx={{ fontSize: 9, height: "15px" }}
              backgroundcolor="#F60000"
              color="#fff"
              borderradius={"3px"}
            />
          ))}
        </Stack>
      );
    }
  };

  const { fields } = useFieldArray({
    control: formAntrian.control,
    name: `size`,
  });

  const addRowDetail = (orderIndex) => {
    const getSize = formAntrian.getValues().size;
    const updatedFields = [...getSize];
    const newDetailOrder = {
      id: makeID(10),
      keterangan: keterangan.map((e) => ({
        id: e.id,
        deskripsi: "",
        name: e.name,
        keterangan_id: e.id,
      })),
      additional: [],
    };
    updatedFields[orderIndex].detail_order.push(newDetailOrder);
    formAntrian.setValue(`size`, updatedFields);
  };

  const { role } = useSelector((state) => state.auth);

  return (
    <>
      <Grid container>
        {fields.map((sizeItem, index) => (
          <Grid
            container
            key={`${sizeItem.size_id}`}
            spacing={2}
            paddingBottom={1}
            display={
              role !== "root"
                ? sizeItem.status
                  ? undefined
                  : "none"
                : undefined
            }
          >
            <Grid
              item
              xs={3}
              sx={{
                textAlign: "right",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Chip
                label={sizeItem.label}
                sx={{
                  backgroundColor: formAntrian.watch(`size.${index}.isActive`)
                    ? "#105174"
                    : "#ACAFB1",
                  color: "#fff",
                  fontWeight: 700,
                  fontSize: "10px",
                  borderRadius: "5px",
                }}
              />
              <SwitchInput
                key={`switch-${sizeItem.size_id}`}
                name={`size.${index}.isActive`}
                control={formAntrian.control}
                sx={{ marginLeft: "0.5rem" }}
                disabled={isLoading}
              />
            </Grid>

            <Grid item xs={3} sx={{ paddingLeft: 2, display: "block" }}>
              <NumberInput
                rules={{
                  required: formAntrian.watch(`size.${index}.isActive`)
                    ? "Harga Harus di isi!"
                    : false,
                  min: {
                    value: formAntrian.watch(`size.${index}.isActive`) ? 0 : 0,
                    message: "Harga Harus di isi!",
                  },
                }}
                name={`size.${index}.harga`}
                control={formAntrian.control}
                label={`Harga Satuan (${sizeItem.label ?? "-"})`}
                defaultValue={0}
                variant={"filled"}
                disabled={!formAntrian.watch(`size.${index}.isActive`)}
              />
            </Grid>

            <Grid
              item
              xs={3}
              alignSelf={"center"}
              sx={{ display: role !== "root" ? "none" : undefined }}
            >
              <NumberInput
                rules={{
                  required: formAntrian.watch(`size.${index} .isActive`)
                    ? "HPP Harus di isi!"
                    : false,
                  min: {
                    value: formAntrian.watch(`size.${index}.isActive`) ? 0 : 0,
                    message: "HPP Harus di isi!",
                  },
                }}
                name={`size.${index}.hpp`}
                control={formAntrian.control}
                label={`HPP Satuan (${sizeItem.label ?? "-"})`}
                defaultValue={0}
                variant={"filled"}
                disabled={!formAntrian.watch(`size.${index}.isActive`)}
              />
            </Grid>

            <Grid
              item
              xs={role !== "root" ? 6 : 3}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Stack
                spacing={1}
                direction="row"
                alignItems={"center"}
                justifyContent={"start"}
              >
                <CheckHarga harga={sizeItem.harga} hpp={sizeItem.hpp} />
              </Stack>
              <Stack
                spacing={1}
                direction="row"
                alignItems={"center"}
                justifyContent={"end"}
              >
                {formAntrian.watch(`size.${index}.isActive`) && (
                  <Typography
                    sx={{
                      color: "#105174",
                      fontWeight: 700,
                      fontSize: "12px",
                    }}
                  >
                    {formAntrian.watch(`size.${index}.detail_order`)?.length ||
                      0}{" "}
                    Pcs
                  </Typography>
                )}
                <IconButton
                  aria-label="add"
                  onClick={() => addRowDetail(index)}
                  disabled={!formAntrian.watch(`size.${index}.isActive`)}
                  sx={{
                    backgroundColor: "#35AAFF",
                    color: "white",
                    borderRadius: "5px",
                    padding: "1px",
                    "&.Mui-disabled": {
                      color: "white",
                      backgroundColor: "#ACAFB1",
                    },
                    "&:hover": {
                      backgroundColor: "rgba(53, 170, 255, 0.8)",
                    },
                  }}
                >
                  <AddIcon />
                </IconButton>
              </Stack>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: -1 }}>
              <Divider />
            </Grid>

            {formAntrian.watch(`size.${index}.isActive`) && (
              <DetailRowField
                orderIndex={index}
                additional={additional}
                control={formAntrian.control}
                keterangan={keterangan}
                watch={formAntrian.watch}
              />
            )}
          </Grid>
        ))}
      </Grid>
      <Grid
        container
        justifyContent={"end"}
        alignItems={"center"}
        marginBottom={"-1rem"}
      >
        <CheckboxInput
          name={"confirmation"}
          control={formAntrian.control}
          label={"Pastikan data sudah benar!"}
          rules={{ required: false }}
          defaultValue={false}
        />
      </Grid>
    </>
  );
};

const DetailRowField = ({ orderIndex, control, additional, watch }) => {
  const { remove } = useFieldArray({
    control,
    name: `size.${orderIndex}.detail_order`,
  });

  const removeRowDetail = (detailOrderIndex) => {
    remove(detailOrderIndex);
  };

  return (
    watch("size").length > 0 &&
    watch(`size.${orderIndex}.detail_order`).map(
      (detailOrder, detailOrderIndex) => (
        <React.Fragment key={detailOrder.id}>
          {detailOrder.keterangan.map((keterangan, keteranganIndex) => (
            <Grid item xs={3} key={keteranganIndex}>
              <Input
                rules={{ required: "" }}
                name={`size.${orderIndex}.detail_order.${detailOrderIndex}.keterangan.${keteranganIndex}.deskripsi`}
                control={control}
                label={keterangan.name}
                defaultValue={keterangan.deskripsi}
              />
            </Grid>
          ))}
          <Grid item xs={3}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={1}
            >
              <MultipleSelect
                disabled={additional.length ? false : true}
                name={`size.${orderIndex}.detail_order.${detailOrderIndex}.additional`}
                control={control}
                label={"Tambahan"}
                options={
                  additional.length
                    ? additional.filter((additional) => additional.status)
                    : []
                }
                rules={{ required: false }}
                defaultValue={detailOrder.additional}
              />
              <IconButton
                aria-label="add"
                onClick={() => removeRowDetail(detailOrderIndex)}
                sx={{
                  color: "#ACAFB1",
                  padding: "1px",
                  "& .MuiSvgIcon-root": {
                    fontSize: 22,
                  },
                  "&:hover": {
                    color: "rgba(255, 0, 0, 0.8)",
                  },
                }}
              >
                <RemoveCircleIcon />
              </IconButton>
            </Stack>
          </Grid>
        </React.Fragment>
      )
    )
  );
};

export default FormSizeQty;
